import React, { Fragment, Suspense, useEffect, useMemo } from "react";
import MultiSkeleton from "../Common/MultiSkeleton";
const RemiaingLimits = React.lazy(() => import("./RemiaingLimits"));
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const RightBox = ({
  beneficiary,
  benefit,
  class_id,
  setBeneficiaryLimits,
  beneficiaryLimits,
  setIsSideBarHidden,
  isLoading,
  chronicForm,
  claim,
  exchange_rate,
}) => {
  // hide this component if there is no data
  const isHidden = useMemo(() => {
    // if the claim is IN and not coeffient or the claim not IN claim
    const notProforma =
      (claim?.benefit?.slug === "IN" &&
        !claim?.healthcare_provider_contract?.proforma_coefficient) ||
      claim?.benefit?.slug !== "IN"
        ? true
        : false;
    return !beneficiary?.memo &&
      !beneficiary?.special_notes &&
      !beneficiary?.policy?.product?.payer?.special_notes &&
      !beneficiary?.policy?.remarks &&
      !beneficiaryLimits &&
      notProforma
      ? true
      : false;
  }, [beneficiaryLimits, JSON.stringify(beneficiary), JSON.stringify(claim)]);

  useEffect(() => {
    setIsSideBarHidden(isHidden);
  }, [isHidden]);

  return (
    <Fragment>
      <PerfectScrollbar
        id="sticky-sidebox"
        className={`${isHidden ? "d-none" : ""}`}
      >
        {/* <div
      className={`bg-primary px-3 py-4 text-break ${isHidden ? "d-none" : ""}`}
      id="sticky-sidebox"
    > */}
        <div
          className="bg-primary px-3 py-4 text-break"
          style={{ minHeight: "100%" }}
        >
          {!isLoading && beneficiary && (
            <Fragment>
              {/* Remaing limit */}
              {!chronicForm ? (
                <Suspense fallback={<MultiSkeleton length={2} />}>
                  <RemiaingLimits
                    policyId={beneficiary?.policy?.id}
                    beneficaryId={beneficiary?.id}
                    benefitId={benefit?.id}
                    class_id={class_id}
                    setBeneficiaryLimits={setBeneficiaryLimits}
                    claim={claim}
                    exchange_rate={exchange_rate}
                  />
                </Suspense>
              ) : null}

              {/* Contract proforma_coefficient  */}
              {claim &&
              claim.healthcare_provider_contract.proforma_coefficient &&
              claim.benefit?.slug === "IN" ? (
                <Fragment>
                  <span className="fw-bold text-white text-uppercase d-block my-1 font-size-14">
                    proforma coefficient
                  </span>
                  <span className="text-white font-size-12 fw-light">
                    {claim.healthcare_provider_contract.proforma_coefficient}
                  </span>
                </Fragment>
              ) : null}
              {/* Beneficiary memo */}
              {beneficiary?.memo ? (
                <Fragment>
                  <span className="fw-bold text-white text-uppercase d-block my-1 font-size-14">
                    Automation Notes
                  </span>
                  <span className="text-white font-size-12 fw-light">
                    {beneficiary?.memo}
                  </span>
                </Fragment>
              ) : null}
              {/* Beneficiary special notes */}
              {beneficiary?.special_notes ? (
                <Fragment>
                  <span className="fw-bold text-white text-uppercase d-block my-1 font-size-14">
                    Special Notes
                  </span>
                  <span className="text-white font-size-12 fw-light">
                    {beneficiary?.special_notes}
                  </span>
                </Fragment>
              ) : null}

              {/* payer speical notes */}
              {beneficiary?.policy?.product?.payer?.special_notes ? (
                <Fragment>
                  <span className="fw-bold text-white d-block my-1 font-size-14 text-truncate">
                    {beneficiary?.policy?.product?.payer?.first_name}
                  </span>
                  <span className="text-white font-size-12 fw-light">
                    {beneficiary?.policy?.product?.payer?.special_notes}
                  </span>
                </Fragment>
              ) : null}

              {/* Policy Remarks */}
              {beneficiary?.policy?.remarks ? (
                <Fragment>
                  <span className="fw-bold text-white d-block my-1 font-size-14">
                    {beneficiary?.policy?.name}
                  </span>
                  <span className="text-white font-size-12 fw-light">
                    {beneficiary?.policy?.remarks}
                  </span>
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </div>
      </PerfectScrollbar>
    </Fragment>
  );
};

export default RightBox;

import { motorPaths } from "common/data/routePaths"

import React, { lazy } from "react"

const motorProvider = lazy(() => import("pages/Motors/MotorProvider"))
const motorProviderForms = lazy(() =>
  import("pages/Motors/MotorProvider/motorProviderForms")
)
const motorDriver = lazy(() => import("pages/Motors/Drivers"))
const motorVehicle = lazy(() => import("pages/Motors/Vehicle"))
const FNOL = lazy(() => import("pages/Motors/FNOL"))
const FNOLForms = lazy(() => import("pages/Motors/FNOL/FNOLForms"))
const FnolValidation = lazy(() => import("pages/Motors/FNOL/Validation"))
const motorExpert = lazy(() => import("pages/Motors/Experts"))
const motorExpertForms = lazy(() =>
  import("pages/Motors/Experts/motorExpertForms")
)
const spareParts = lazy(() => import("pages/Motors/SpareParts"))
const motorClaims = lazy(() => import("pages/Motors/Claims"))
const motoClaimForms = lazy(() => import("pages/Motors/Claims/motoClaimForms"))
const MotoPolicies = lazy(() => import("pages/Motors/MotoPolicies"))
const MotoPoliciesForms = lazy(() =>
  import("pages/Motors/MotoPolicies/MotoPoliciesForms")
)
const UploadMotoPolicies = lazy(() =>
  import("pages/Motors/MotoPolicies/UploadMotoPolicies")
)
const MotoInsured = lazy(() => import("pages/Motors/MotoInsured"))
const TowProviders = lazy(() => import("pages/Motors/Tow"))
const Surveyor = lazy(() => import("pages/Motors/Surviyor"))
const Suppliers = lazy(() => import("pages/Motors/Suppliers"))
const Panels = lazy(() => import("pages/Motors/Panels"))
const Tables = lazy(() => import("pages/Tables"))
const FnolPrint = lazy(() => import("pages/Motors/FNOL/PDF/FnolPrint"))
const MotoClaimPrint = lazy(() =>
  import("pages/Motors/Claims/PDF/MotoClaimPrint")
)
const UploadExpertFees = lazy(() =>
  import("pages/Motors/FNOL/UploadExpertFees")
)
const FnolDelegation = lazy(() => import("pages/Motors/FNOL/FnolDelegation"))

// vehicle brackets
const VehicleBrackets = lazy(() => import("pages/Motors/VehicleBrackets"))

// motor batches
const MotorBatches = lazy(() => import("pages/Motors/MotorBatches"))
// motor batches
const motorBatchForms = lazy(() =>
  import("pages/Motors/MotorBatches/motorBatchesForms")
)

// desctructure the paths
const {
  motoProvider,
  motoDriver,
  fnol,
  fnolValidation,
  motoVehicle,
  motoExpert,
  motoSpareParts,
  motoClaims,
  motoPolicies,
  motoInsured,
  towProviders,
  surveyors,
  supplier,
  panels,
  survey,
  vehicleBrackets,
  fnolDelegation,
  motorBatches,
} = motorPaths

export const motorRoutes = [
  // Motor Provider
  { path: motoProvider, component: motorProvider },
  { path: `${motoProvider}/add`, component: motorProviderForms },
  { path: `${motoProvider}/edit/:id`, component: motorProviderForms },
  { path: `${motoProvider}/view/:id`, component: motorProviderForms },

  // Motor driver
  { path: motoDriver, component: motorDriver },
  // Motor vehicle
  { path: motoVehicle, component: motorVehicle },
  // FNOL
  { path: fnol, component: FNOL },
  { path: fnolValidation, component: FnolValidation },
  // FNOL Forms
  { path: `${fnol}/add`, component: FNOLForms },
  { path: `${fnol}/edit/:id`, component: FNOLForms },
  { path: `${fnol}/view/:id`, component: FNOLForms },
  { path: `${fnol}/print/:id`, component: FnolPrint },
  { path: `${fnol}/import`, component: UploadExpertFees },
  // vehicle brackets
  { path: fnolDelegation, component: FnolDelegation },

  // experts
  { path: motoExpert, component: motorExpert },
  // experts Forms
  { path: `${motoExpert}/add`, component: motorExpertForms },
  { path: `${motoExpert}/edit/:id`, component: motorExpertForms },
  { path: `${motoExpert}/view/:id`, component: motorExpertForms },
  // Motor spareParts
  { path: motoSpareParts, component: spareParts },
  //MOtor Claims
  { path: motoClaims, component: motorClaims },
  // motoclaims Forms
  { path: `${motoClaims}/add`, component: motoClaimForms },
  { path: `${motoClaims}/edit/:id`, component: motoClaimForms },
  { path: `${motoClaims}/view/:id`, component: motoClaimForms },
  { path: `${motoClaims}/print/:id`, component: MotoClaimPrint },

  // moto policies
  // index
  { path: motoPolicies, component: MotoPolicies },
  // import
  { path: `${motoPolicies}/import`, component: UploadMotoPolicies },

  // { path: `${motoPolicies}/edit/:id`, component: MotoPoliciesForms },
  // view
  { path: `${motoPolicies}/add`, component: MotoPoliciesForms },
  { path: `${motoPolicies}/edit/:id`, component: MotoPoliciesForms },
  { path: `${motoPolicies}/view/:id`, component: MotoPoliciesForms },

  // moto insured
  { path: motoInsured, component: MotoInsured },

  //Tow
  { path: towProviders, component: TowProviders },
  //surveyor
  { path: surveyors, component: Surveyor },
  //supplier
  { path: supplier, component: Suppliers },
  //panels
  { path: panels, component: Panels },

  //survey
  { path: `${survey}/:id`, component: Tables },
  // vehicle brackets
  { path: vehicleBrackets, component: VehicleBrackets },

  // motor batches
  { path: motorBatches, component: MotorBatches },
  { path: `${motorBatches}/add`, component: motorBatchForms },
  { path: `${motorBatches}/edit/:id`, component: motorBatchForms },
  { path: `${motorBatches}/view/:id`, component: motorBatchForms },
]

import { DefaultColumnFilter } from "components/Common/filters";
import { previewDateFormat } from "constants/TPA";
import moment from "moment";
import parse from "html-react-parser";
import { isString } from "lodash";

export default function generateColumns(fields) {
  return Array.isArray(fields)
    ? fields
        .sort((a, b) => {
          return a.display_order - b.display_order;
        })
        .map((field) => ({
          Header: field.label_name ? parse(field.label_name) : "",
          accessor:
            field.index_name?.includes(".") && field.field_type === "array"
              ? field.index_name.split(".")[0]
              : field.index_name,
          show: parseInt(field.table_use),
          name: field.name,
          field_URI: field?.uri || "",
          column_name: field?.column_name || "",
          customSearchParameter:
            field.name.split("-").length > 1 ? field.name.split("-")[1] : "",
          Filter: DefaultColumnFilter,
          disableSortBy: field.field_type === "array",
          fieldType: field.field_type,
          Cell: (cellProps) => {
            const value = cellProps.cell.value;
            if (field.field_type?.toLowerCase() === "date") {
              return value ? moment(value).format(previewDateFormat) : "";
            }
            if (field.field_type === "dateTime") {
              return value
                ? moment(value).format(`${previewDateFormat} hh:mm a`)
                : "";
            }
            if (field.field_type?.toLowerCase() === "array") {
              return value?.map((val, i) => {
                const name =
                  (field.index_name?.includes(".")
                    ? val[field.index_name.split(".")[1]]
                    : val.name) + (i === value.length - 1 ? "" : ", ");
                return name;
              });
            } else if (field.field_type?.toLowerCase() === "boolean") {
              return value == 1 ? "Yes" : "No";
            } else if (field.field_type?.toLowerCase() === "text-editor") {
              return value
                ? value?.replace(/<[^>]*>/g, "")?.replace(/&nbsp;/g, " ")
                : "";
            } else if (field.field_type?.toLowerCase() === "number") {
              return value
                ? isString(value)
                  ? +value?.toLocaleString("en-US")
                  : value?.toLocaleString("en-US")
                : 0;
            }
            return value;
          },
        }))
    : [];
}

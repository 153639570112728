export const claimStatus = {
  processed: 270,
  received: 267,
  approved: 269,
  declined: 435,
  pending: 437,
  inProgress: 268,
  unused: 434,
  settled: 436,
  returned: 438,
  forward: 738,
  routed: 738,
  inoviced: 744,
  collected: 436, //claimStatus_settled
  partialCollected: 745, // claimStatus_partialSettled
  paid: 1453, //claimStatus_paid
};

export const chronicFormStatus = {
  approved: 767,
  declined: 765,
  pending: 764,
  unused: 768,
  suspended: 769,
  exhausted: 770,
};

export const claimServiceActions = {
  priorApproval: 433,
  rejected: 432,
  approved: 288,
};

// export const benefitIds = {
//   inPatient: 1,
//   outPatient: 256,
//   pharmacy: 257,
//   emergencyRoom: 258,
// }
export const benefitIds = {
  inPatient: 1,
  outPatient: 218,
  ambulatory: 218,
  pharmacy: 277,
  emergencyRoom: 11,
  doctorVisits: 292,
  dental: 293,
  optical: 400,
};

export const claimsTypes = {
  reimbursement: 234,
  directBilling: 233,
};

export const claimRuleTriggerPredefined = [
  // physician speciality
  {
    triggerId: 442,
    predefinedId: 8,
  },
  // Beneficiaary Depenedency
  {
    triggerId: 484,
    predefinedId: 176,
  },
  // Beneficiaary Geneder
  {
    triggerId: 446,
    predefinedId: 173,
  },
  // Family of cause
  {
    triggerId: 443,
    predefinedId: 235,
  },
  // Class Trigger
  {
    triggerId: 1925,
    predefinedId: 15,
  },
];

export const allowedPrintPermissionStatuses = [
  claimStatus.approved,
  claimStatus.declined,
  claimStatus.returned,
  claimStatus.received,
];
export const hidePrintPermissionStatuses = [
  claimStatus.inProgress,
  claimStatus.pending,
  claimStatus.unused,
];

export const hideProcessingPermissionStatuses = [
  ...hidePrintPermissionStatuses,
  claimStatus.approved,
  claimStatus.returned,
  claimStatus.declined,
  claimStatus.routed,
];

export const declinedButtonStatuses = [
  claimStatus.declined,
  claimStatus.returned,
  claimStatus.received,
  claimStatus.processed,
];
export const reimbRemovedeclinedButtonStatuses = [
  claimStatus.declined,
  claimStatus.returned,
  claimStatus.processed,
];
export const saveButtonStatuses = [
  ...declinedButtonStatuses,
  claimStatus.unused,
  claimStatus.settled,
];
export const saveReimbursemntButtonStatuses = [
  ...declinedButtonStatuses.filter(
    (item) => item !== claimStatus.received && item !== claimStatus.returned
  ),
  claimStatus.unused,
  claimStatus.settled,
];
export const approvedButtonStatuses = [
  claimStatus.inProgress,
  claimStatus.pending,
  claimStatus.declined,
];
export const pendingButtonStatuses = [
  claimStatus.processed,
  claimStatus.unused,
  claimStatus.settled,
  claimStatus.received,
  claimStatus.returned,
];

export const correctedSlugs = [
  "claimStatus_invoiced",
  "claimStatus_partialSettled",
  "claimStatus_paid",
  "claimStatus_settled",
];

export const hideSaveButtonInProcess = [
  "claimStatus_settled",
  "claimStatus_invoiced",
  "claimStatus_partialSettled",
];
export const hideVoidStatusButtonSlugs = [
  "claimStatus_paid",
  "claimStatus_partialSettled",
  "claimStatus_settled",
  "claimStatus_invoiced",
  "claimStatus_processed",
  "claimStatus_received",
  "claimStatus_unused",
  "claimStatus_routed",
];

export const claimErrorMessages = {
  // exceedLimits:
  //   "The Estimation Amount has surpassed the FOB Remaining Limit. Please Check the Beneficiary Note Box",
  specialCoverageAmount: "Special Coverage Amount is Required",
  exceedLimits:
    "The Estimation Amount cannot be greater than the Remaining Limit. Please Check the Beneficiary's Notes if any",
  amountSumexceedLimits:
    "The Sum of benefits amounts has surpassed the Approved Estimation Amount.",
  // selectRejectReason: "Please select rejected reason to proceed.",
  selectRejectReason:
    "A claim that contains only rejected items cannot be saved",
  changeFromPrior: "Please change status from Proir Approval",
  emptyServices: "Please add Service to proccess",
  notRejectedNotApproved:
    "Please check the item status before proceeding with claim processing.",
  cantAppovedItem:
    "This item cannot be approved since the total approved amount is 0.",
  lengthOfStayGR:
    "The length of stay exceeds the remaining guaranteed replacement (GR) days.",
  allRejectedServices: "Can’t approved with all service rejected",

  nonSplitProvider: "Provider Payment Method is No-split Fees",
};

export const countSlugs = {
  all: "claims-grouped-by-type-and-status",
  approved: "claims-grouped-by-type-and-approved-status-without-discharge-date",
  claimAssign: "claim-group-by-assigned-user-type-limited-claim-status",
  claimAssignApproved:
    "assigned-claims-grouped-by-type-and-approved-status-without-discharge-date",
  unreadChats: "claims-unread-chat-count",
};

import React, { Fragment, useState } from "react";
import { SingleRowAction } from "../Common/Tables";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SearchSelect } from "../Common/Forms";
import { HEALTHCARECONTRACTS, USERS } from "helpers/url_helper";
import { apiErrorrHandler, NotificationMessage } from "utils";
import { updateClaim } from "helpers/Claims/ClaimForm";
import { buttonsTitles } from "common/data";
import CustomButton from "../Common/General/CustomButton";

const UpdateClaimContract = ({ claim, refetchList }) => {
  const [isModal, setIsModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState("");

  const [isSaving, setSaving] = useState(false);

  const handleUpdateClaim = async () => {
    setSaving(true);
    try {
      await updateClaim({
        id: claim.id,
        beneficiary_id: claim.beneficiary.id,
        admission_date: claim.admission_date,
        benefit_id: claim.benefit.id,
        status_id: claim.status.id,
        is_used: claim.is_used,
        healthcare_provider_contract_id: selectedContract,
      });
      NotificationMessage("Success", "Claim Routed Successfully");
      setIsModal(false);
      if (refetchList) {
        refetchList();
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Fragment>
      <SingleRowAction
        onClick={() => setIsModal(true)}
        iconClass="bx bx-food-menu"
        actionName={"Change Claim’s Contract"}
        record={claim}
      />

      {isModal && (
        <Modal isOpen={isModal} toggle={() => setIsModal(false)} centered>
          <ModalHeader toggle={() => setIsModal(false)}>
            Change Claim’s Contract
          </ModalHeader>
          <ModalBody>
            <SearchSelect
              id="711"
              name="healthcare_provider_contract_id"
              inputField={{
                label_name: "Contract",
                predefined_value: null,
              }}
              label="Contract"
              placeholder="Select Contract"
              value={selectedContract}
              customOnChange={(selected) =>
                setSelectedContract(selected ? selected.id : "")
              }
              mappingValue="id"
              mappingLabel="reference"
              url={HEALTHCARECONTRACTS}
              urlAddOns={{
                healthcare_provider_id: claim?.healthcare_provider?.id,
              }}
              wrapperClassNames="mb-3"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="w-md"
              onClick={() => setIsModal(false)}
            >
              {buttonsTitles.close}
            </Button>
            <CustomButton
              className="btn btn-secondary w-md"
              disabled={!selectedContract ? true : false}
              onClick={() => {
                if (!selectedContract) {
                  return;
                }
                handleUpdateClaim();
              }}
              isSaving={isSaving}
              title={buttonsTitles.save}
            />
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default UpdateClaimContract;

/* Paths */
import { claimPaths } from "common/data/routePaths"
import { lazy } from "react"

const BatchClaim = lazy(() => import("pages/Claims/BatchClaim"))
const AddBatchClaimGroup = lazy(() =>
  import("pages/Claims/BatchClaim/AddBatchClaimGroup")
)
const BatchClaimForms = lazy(() =>
  import("pages/Claims/BatchClaim/BatchClaimForms")
)
const BatchClaimPdf = lazy(() =>
  import("pages/Claims/BatchClaim/BatchClaimPrint/BatchClaimpdf")
)
const ChronicForm = lazy(() => import("pages/Claims/ChronicForm"))
// Chronic Prescription single
const ChronicPrescriptionPDF = lazy(() =>
  import("pages/Claims/ChronicForm/ChronicPrescriptionPDF")
)

// Chronic Prescriptions bulk
const BulkChronicPrescriptions = lazy(() =>
  import(
    "pages/Claims/ChronicForm/ChronicPrescriptionPDF/BulkChronicPrescriptions"
  )
)
const Forms = lazy(() => import("pages/Claims/ChronicForm/Forms"))

/*
Components
*/
// claim form
const ClaimForm = lazy(() => import("pages/Claims/ClaimForm"))
// claim form
const UnreadChats = lazy(() => import("pages/Claims/UnreadChats"))

// claim proceesing
const ClaimProcessing = lazy(() => import("pages/Claims/ClaimProcessing/index"))

// ArbitrationInvoices
const ArbitrationInvoices = lazy(() =>
  import("pages/Claims/ArbitrationInvoices")
)
// claim print
const ClaimPrint = lazy(() => import("pages/Claims/ClaimForm/Print/ClaimPrint"))
const ClaimApprovalPrint = lazy(() =>
  import("pages/Claims/ClaimForm/Print/ClaimApprovalPrint")
)
// claim search results
const SearchResults = lazy(() => import("pages/Claims/ClaimForm/SearchResults"))

// Add Claim
const AddClaimForm = lazy(() => import("pages/Claims/ClaimForm/Tabs"))
// claim Validation
const ClaimValidation = lazy(() => import("pages/Claims/ClaimValidation"))
// bulk print
const BatchBulkPrint = lazy(() =>
  import("pages/Claims/BatchClaim/BatchBulkCard/BatchBulkPrint")
)

// claim assingment
const ClaimAssignment = lazy(() => import("pages/Claims/ClaimAssignment"))

// reimbursement Claim
const ReimbursementClaim = lazy(() => import("pages/Claims/ReimbursementClaim"))
// reimbursement Claim Froms
const ReimbursementClaimForms = lazy(() =>
  import("pages/Claims/ReimbursementClaim/Forms")
)

// claim MedicalOpinion

const MedicalOpinion = lazy(() => import("pages/Claims/MedicalOpinion"))
const MedicalForm = lazy(() =>
  import("pages/Claims/MedicalOpinion/MedicalForm")
)
const MedicalOpinionPrint = lazy(() =>
  import("pages/Claims/MedicalOpinion/Print/MedicalOpinionPrint")
)

// Medical Review

const MedicalReview = lazy(() => import("pages/Claims/MedicalReview"))

// approval requests

const ApprovalRequests = lazy(() => import("pages/Claims/ApprovalRequests"))
const ApprovalForm = lazy(() =>
  import("pages/Claims/ApprovalRequests/ApprovalForm")
)
const ClaimHistory = lazy(() => import("pages/General/ClaimHistory"))
const ClaimHistoryViewForm = lazy(() =>
  import("pages/General/ClaimHistory/ClaimHistoryViewForm")
)
const CaseSummaryPdf = lazy(() =>
  import("pages/Claims/ClaimForm/Print/CaseSummaryPdf")
)

// PM claim Prescription
const PMPRESCRIPTION = lazy(() =>
  import("pages/Claims/ClaimForm/PrescriptionPDF")
)
const BatchReceiving = lazy(() => import("pages/Claims/BatchReceiving"))

const EBilling = lazy(() => import("pages/Claims/EBilling"))
const MobileScanning = lazy(() => import("pages/Claims/MobileScanning"))
const {
  claimBatch,
  claimForm: claimFormPath,
  reinmbursementClaim,
  claimProcessing,
  viewProcessing,
  claimAssignment,
  arbitrationInvoices,
  medicalOpinion,
  medicalReview,
  unreadChats,
  approvalRequests,
  claimHistory,
  generalBusinessClaim,
  PMClaimForm,
  chronicForm,
  claimValidation,
} = claimPaths
export const ClaimRoutes = [
  // Claim Form
  { path: claimFormPath, component: ClaimForm },

  // General Bussiness Claims
  { path: generalBusinessClaim, component: ClaimForm },

  // PM only Claims
  { path: PMClaimForm, component: ClaimForm },
  // Claim Processing
  { path: claimProcessing, component: ClaimProcessing },
  // Add Claim
  { path: `${claimFormPath}/add`, component: AddClaimForm },
  // Updated Claim
  { path: `${claimFormPath}/edit/:claimId`, component: AddClaimForm },
  // Processing Claim
  { path: `${claimFormPath}/processing/:claimId`, component: AddClaimForm },
  //view processing Processing Claim
  {
    path: `${claimFormPath}/${viewProcessing}/:claimId`,
    component: AddClaimForm,
  },
  // search results Claim
  { path: `${claimFormPath}/search-results`, component: SearchResults },
  // print Claim
  {
    path: `${claimFormPath}/print/:claimId`,
    component: ClaimPrint,
  },
  // print Approval Claim
  {
    path: `${claimFormPath}/print-approval/:claimId`,
    component: ClaimApprovalPrint,
  },
  // print case summary
  {
    path: `${claimFormPath}/case-summary/:claimId`,
    component: CaseSummaryPdf,
  },

  // print case summary
  {
    path: `${claimFormPath}/prescription/:claimId`,
    component: PMPRESCRIPTION,
  },

  // view Claim
  {
    path: `${claimFormPath}/view/:claimId`,
    component: AddClaimForm,
  },

  // Reinmbursement Claim
  { path: reinmbursementClaim, component: ReimbursementClaim },
  // Add Reinmbursement Claim
  { path: `${reinmbursementClaim}/add`, component: ReimbursementClaimForms },
  // Updated Reinmbursement Claim
  {
    path: `${reinmbursementClaim}/edit/:claimId`,
    component: ReimbursementClaimForms,
  },
  // Processing Reinmbursement Claim
  {
    path: `${reinmbursementClaim}/processing/:claimId`,
    component: ReimbursementClaimForms,
  },
  //view processing Processing Reinmbursement Claim
  {
    path: `${reinmbursementClaim}/${viewProcessing}/:claimId`,
    component: ReimbursementClaimForms,
  },
  //view  Reinmbursement Claim
  {
    path: `${reinmbursementClaim}/view/:claimId`,
    component: ReimbursementClaimForms,
  },

  // Claim Validation
  { path: claimValidation, component: ClaimValidation },
  // Claim Validation with encrypted data
  {
    path: `${claimValidation}/:encryptedData`,
    component: ClaimValidation,
  },

  // Batch Claim
  { path: claimBatch, component: BatchClaim },
  // Add Batch Claim
  { path: `${claimBatch}/add`, component: BatchClaimForms },
  // Add Batch Claim Group
  { path: `${claimBatch}/group`, component: AddBatchClaimGroup },
  // Edit Batch Claim
  { path: `${claimBatch}/edit/:batchClaimId`, component: BatchClaimForms },
  // View Batch Claim
  { path: `${claimBatch}/view/:batchClaimId`, component: BatchClaimForms },
  // Print Receipt
  { path: `${claimBatch}/print/:id`, component: BatchClaimPdf },
  // Print bulk card
  { path: `${claimBatch}/bulk-card-print`, component: BatchBulkPrint },

  /* Chronic Form */

  { path: chronicForm, component: ChronicForm },
  // Add Chronic
  { path: `${chronicForm}/add`, component: Forms },
  // Edit Chronic
  {
    path: `${chronicForm}/edit/:chronicId`,
    component: Forms,
  },
  // View Chronic
  {
    path: `${chronicForm}/view/:chronicId`,
    component: Forms,
  },
  // Print Chronic Prescription single
  {
    path: `${chronicForm}/prescription/:chronicId`,
    component: ChronicPrescriptionPDF,
  },
  // Print Chronic Prescriptions Bulk
  {
    path: `${chronicForm}/prescriptions/:beneficiaryId`,
    component: BulkChronicPrescriptions,
  },

  // Claim Assignment
  { path: claimAssignment, component: ClaimAssignment },

  // Arbitration Invoices
  { path: arbitrationInvoices, component: ArbitrationInvoices },

  // MedicalOpinion
  { path: medicalOpinion, component: MedicalOpinion },
  { path: `${medicalOpinion}/add`, component: MedicalForm },
  { path: `${medicalOpinion}/add/:claimId`, component: MedicalForm },
  { path: `${medicalOpinion}/edit/:id`, component: MedicalForm },
  { path: `${medicalOpinion}/view/:id`, component: MedicalForm },
  { path: `${medicalOpinion}/print/:id`, component: MedicalOpinionPrint },

  // Medical Review
  { path: medicalReview, component: MedicalReview },
  // unread chats
  { path: unreadChats, component: UnreadChats },

  // Approval Requests
  { path: approvalRequests, component: ApprovalRequests },
  { path: `${approvalRequests}/edit/:id`, component: ApprovalForm },
  { path: `${approvalRequests}/add`, component: ApprovalForm },

  //claim history
  { path: claimHistory, component: ClaimHistory },
  { path: `${claimHistory}/:data`, component: ClaimHistory },
  { path: `${claimHistory}/view/:id`, component: ClaimHistoryViewForm },
  { path: "/ebilling", component: EBilling },
  {
    path: "/mobile-scanning",
    component: MobileScanning,
  },
  {
    path: "/batch-receiving",
    component: BatchReceiving,
  },
]

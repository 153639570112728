import React, { Fragment, useEffect } from "react";
import Dropzone from "react-dropzone";
import { Col, Row } from "reactstrap";
import { formatBytes } from "utils";
import DropZonePreviews from "./DropZonePreviews";
import Excel from "assets/images/fileTypes/excel.png";
import PDF from "assets/images/fileTypes/pdf.png";
import Word from "assets/images/fileTypes/word.png";
import MP3 from "assets/images/fileTypes/mp3.png";
import { usePageType } from "hooks";
import moment from "moment";

const DropZone = ({
  setselectedFiles,
  selectedFiles,
  includeImageAgin,
  isDownloadAll,
  zipFileName,
  label,
  isRequired,
  single,
  isVertical,
  inCludeCloudIcon,
  removeDeleteAction = false,
  acceptededMIMEs = undefined,
  showAll = false,
}) => {
  const { isViewPage, isViewProcessingPage } = usePageType();
  // generate preview base on extension
  const generatePreview = (file) => {
    // if file has name put it else put the path comming from DB
    const fileName = file.name ? file.name : file.path;
    // extract extension from fileName
    const extension = fileName?.split(".")?.pop();
    let imagePreview;
    if (extension === "pdf") {
      imagePreview = PDF;
    } else if (extension === "docx" || extension === "doc") {
      imagePreview = Word;
    } else if (extension === "csv" || extension === "xlsx") {
      imagePreview = Excel;
    } else if (extension === "mp3") {
      imagePreview = MP3;
    } else {
      imagePreview = file.id ? file?.path : URL.createObjectURL(file);
    }

    return imagePreview;
  };

  // check if the file is image and last modfied date of ite
  // is older than 2 days show alert

  const checkImageAge = (file) => {
    return file?.type?.split("/")[0] === "image" &&
      moment().diff(file?.lastModifiedDate, "days") > 2
      ? true
      : false;
  };

  // handle accepted file
  function handleAcceptedFiles(files) {
    files.map((file) => {
      const isAged = includeImageAgin ? checkImageAge(file) : false;
      const imagePreview = generatePreview(file);
      Object.assign(file, {
        preview: imagePreview,
        formattedSize: formatBytes(file.size),
        isAged,
      });
    });

    const newFiles = [...files, ...selectedFiles];
    setselectedFiles(newFiles);
  }

  useEffect(() => {
    const oldFiles = selectedFiles.map((file) => {
      const imagePreview = generatePreview(file);
      return {
        ...file,
        name: file?.path?.split("/").pop(),
        preview: imagePreview,
      };
    });
    setselectedFiles(oldFiles);
  }, []);

  return (
    <Fragment>
      <Row className="my-2">
        <label
          className={`${isVertical ? "col-md-12" : "col-md-2"} form-label ${
            isRequired ? "required-label" : ""
          }`}
        >
          {label ? label : "Attached Files"}
        </label>
        <Col md={isVertical ? 12 : 10}>
          {!isViewPage && !isViewProcessingPage && (
            <Dropzone
              multiple={single ? false : true}
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
              accept={acceptededMIMEs}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" style={{ minHeight: "40px" }}>
                  <div
                    className="dz-message needsclick mt-2"
                    style={{ padding: "0px" }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {inCludeCloudIcon ? (
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                    ) : null}

                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
          )}

          <DropZonePreviews
            setselectedFiles={setselectedFiles}
            selectedFiles={selectedFiles}
            isDownloadAll={isDownloadAll}
            showAll={showAll}
            zipFileName={zipFileName}
            removeDeleteAction={removeDeleteAction}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default DropZone;

import React, { Fragment, useState } from "react";
import { SingleRowAction } from "../Common/Tables";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SearchSelect } from "../Common/Forms";
import { USERS } from "helpers/url_helper";
import { apiErrorrHandler, NotificationMessage } from "utils";
import { updateClaim } from "helpers/Claims/ClaimForm";
import { buttonsTitles } from "common/data";
import CustomButton from "../Common/General/CustomButton";

const RouteClaimToUserAction = ({ row: claim, refetchList }) => {
  const [isModal, setIsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const [isSaving, setSaving] = useState(false);

  const handleRouteClaimToUser = async () => {
    setSaving(true);
    try {
      await updateClaim({
        id: claim.id,
        beneficiary_id: claim.beneficiary.id,
        admission_date: claim.admission_date,
        benefit_id: claim.benefit.id,
        status_id: claim.status.id,
        is_used: claim.is_used,
        routed_to_user_id: selectedUser,
      });
      NotificationMessage("Success", "Claim Routed Successfully");
      setIsModal(false);
      if (refetchList) {
        refetchList();
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Fragment>
      <SingleRowAction
        onClick={() => setIsModal(true)}
        iconClass="mdi mdi-account-arrow-right"
        actionName={"Route Claim"}
        record={claim}
      />

      {isModal && (
        <Modal isOpen={isModal} toggle={() => setIsModal(false)} centered>
          <ModalHeader toggle={() => setIsModal(false)}>
            Route Claim
          </ModalHeader>
          <ModalBody>
            <SearchSelect
              id="711"
              name="routed_to_user_id"
              inputField={{
                label_name: "User",
                predefined_value: null,
              }}
              label="User"
              placeholder="Select User"
              value={selectedUser}
              customOnChange={(selected) =>
                setSelectedUser(selected ? selected.id : "")
              }
              mappingValue="id"
              mappingLabel="first_name"
              generateCustomLabels={(item) =>
                `${item.first_name} ${item.last_name}`
              }
              url={USERS}
              urlAddOns={{
                access_type_id: "1",
                job_role_id: 50,
                is_active: 1,
              }}
              generateDisabledOptions={(option) => option.is_active === 0}
              wrapperClassNames="mb-3"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="w-md"
              onClick={() => setIsModal(false)}
            >
              {buttonsTitles.close}
            </Button>
            <CustomButton
              className="btn btn-secondary w-md"
              disabled={!selectedUser ? true : false}
              onClick={() => {
                if (!selectedUser) {
                  return;
                }
                handleRouteClaimToUser();
              }}
              isSaving={isSaving}
              title={buttonsTitles.save}
            />
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default RouteClaimToUserAction;

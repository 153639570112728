import { request } from "../api_helper";

// export const generalRequest = ({
//   page,
//   per_page,
//   sort_by,
//   sort_type,
//   search_keys,
//   search_values,
//   search_value,
//   view,
//   url,
//   ...rest
// }) =>
//   request({
//     url,
//     method: "GET",
//     params: {
//       page: page ? page : 1,
//       paginate: per_page ? per_page : 10,
//       sort_by: sort_by ? sort_by : "id",
//       sort_type,
//       search_keys,
//       search_values,
//       search_value,
//       view,
//       ...rest,
//     },
//   })
// general GET request

export const generalRequest = ({ url, ...rest }) =>
  request({
    url,
    method: "GET",
    params: {
      ...rest,
    },
  });

// general POST request
export const postRequest = (url, data, repeat = false) =>
  request({
    url,
    method: "POST",
    data,
    repeat,
  });
// general PUT request
export const putRequest = (url, data) =>
  request({
    url,
    method: "PUT",
    data,
  });
// general PATCH request
export const patchRequest = (url, data) =>
  request({
    url,
    method: "PATCH",
    data,
  });
// general DELETE request
export const deleteRequest = (url) =>
  request({
    url,
    method: "DELETE",
  });

import { addFile } from "helpers/General/files_helper";
import { toast } from "react-hot-toast";
import { NotificationMessage } from "utils/NotificationMessage";
import { apiErrorrHandler } from "utils/apiErrorHandler";

import imageCompression from "browser-image-compression";
import { generalRequest } from "helpers/General/general_request";
import { base64ToBlob } from "utils";

const compressImages = async (images) => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 800,
    useWebWorker: true,
  };
  let compressedImages = [];

  // this package compress image only
  // if type is image it will compress it else it will return it as it is
  for (const image of images) {
    if (image?.type?.startsWith("image/")) {
      try {
        const compressedFile = await imageCompression(image, options);
        // the compress file is blob
        // we need to convert it to file
        const blobToFile = new File([compressedFile], compressedFile?.name, {
          type: compressedFile.type,
        });
        compressedImages.push(blobToFile);
      } catch (error) {
        console.log(error);
      }
    } else {
      compressedImages.push(image);
    }
  }

  return compressedImages;
};

export const UploadFiles = async (selectedFiles, description, typeId) => {
  let filesIds = [];
  // old files (if it has an ID that means it’S already uploaded in DB)
  const oldFilesIds = selectedFiles
    .filter((item) => item.id)
    .map((item) => item.id);

  // new files (files without IDs (new files that the user has just dropped))
  const newFiles = selectedFiles.filter((item) => !item.id);
  try {
    if (newFiles.length > 0) {
      const formData = new FormData();
      // newFiles.map((file, index) => formData.append(`paths[${index}]`, file))
      // formData.append(
      //   "description",
      //   description ? description : `Health Care Contract File`
      // )
      // if (typeId) {
      //   formData.append("type_id", typeId)
      // }
      // compress files
      const compressed = await compressImages(newFiles);
      compressed.map((file, index) => formData.append(`paths[${index}]`, file));
      formData.append(
        "description",
        description ? description : `Health Care Contract File`
      );
      if (typeId) {
        formData.append("type_id", typeId);
      }
      const filesRes = await addFile(formData);
      toast.success("Files Uploaded Successfully");
      const getFileIds = filesRes?.item?.map((file) => file.id);
      filesIds = getFileIds;
    }
  } catch (error) {
    const errorMessage = apiErrorrHandler(error);
    NotificationMessage("Error", errorMessage);
    return;
  }

  return [...filesIds, ...oldFilesIds];
};

export const uploadFiles = async (selectedFiles, description, typeId) => {
  let filesIds = [];
  // old files (if it has an ID that means it’S already uploaded in DB)
  const oldFilesIds = selectedFiles.filter((item) => item.id);

  // new files (files without IDs (new files that the user has just dropped))
  const newFiles = selectedFiles.filter((item) => !item.id);
  try {
    if (newFiles.length > 0) {
      const formData = new FormData();

      // newFiles.map((file, index) => formData.append(`paths[${index}]`, file))
      // formData.append(
      //   "description",
      //   description ? description : `Health Care Contract File`
      // )
      // if (typeId) {
      //   formData.append("type_id", typeId)
      // }

      const compressed = await compressImages(newFiles);
      compressed.map((file, index) => formData.append(`paths[${index}]`, file));
      formData.append(
        "description",
        description ? description : `Health Care Contract File`
      );
      if (typeId) {
        formData.append("type_id", typeId);
      }

      const filesRes = await addFile(formData);
      toast.success("Files Uploaded Successfully");
      const getFileIds = filesRes?.item?.map((file) => file);
      filesIds = getFileIds;
    }
  } catch (error) {
    const errorMessage = apiErrorrHandler(error);
    NotificationMessage("Error", errorMessage);
    return;
  }

  return [...filesIds, ...oldFilesIds];
};

// download single file based on click
export const downloadFile = async (fileURL, name) => {
  if (!fileURL) {
    throw new Error("Resource URL not provided! You need to provide one");
  }
  try {
    const res = await generalRequest({
      url: `file-info?file_name=${name}`,
    });
    const fileType = res?.item?.file_type;
    const base64String = `data:${fileType};base64,${res?.item?.base64}`;
    const filename = res?.item?.file_name;

    // Convert the Base64 string to a Blob
    const blob = base64ToBlob(base64String, fileType);

    // Create a URL for the Blob and make it downloadable
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    const errorMessage = apiErrorrHandler(error);
    NotificationMessage("error", errorMessage);
  }
};

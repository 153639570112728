import { stakeholderPaths } from "common/data/routePaths";
import SpecialNotesModal from "components/TP/Common/Modals/SpecialNotesModal";
import MultiSkeleton from "components/TP/Common/MultiSkeleton";
import React, { Fragment, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { beneficaryBenefitTOBId } from "utils/modulesIds";
import MedicalFilesTableModal from "./MedicalFilesTableModal";
import { buttonsTitles, modalsStates } from "common/data";
import NetworkContractsModal from "./NetworkContractsModal";
import ChatModal from "components/TP/Chat/ChatModal";
import WhatssAppModal from "./WhatssAppModal";
import FocSpentModal from "../Beneficiary/FocSpentModal";
import FOBSpentModal from "../Beneficiary/FOBSpentModal";
import SendEmailModal from "./SendEmailModal";
import { uniqBy } from "lodash";
import SourceTaskDropdown from "components/TP/Common/General/SourceTaskDropdown/SourceTaskDropdown";
import ClaimMOCasesModal from "./ClaimMOCasesModal";
import ClaimChatNotification from "../Chat/ClaimChatNotification";
import { SendSMSModal } from "components/TP/Common/Modals";
import ClaimHistoryActionsModal from "pages/Claims/ClaimForm/Tabs/Accordions/ClaimHistoryActionsModal";

const ClaimsBreadcrumbAddons = ({
  beneficiary,
  isLoading,
  claim,
  networkId,
  isChronicForm = false,
}) => {
  const { claimId } = useParams();

  const [network, setNetwork] = useState(null);
  // store modal states for all modals
  // in this file
  const [isModal, setIsModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  const { isVIP } = useMemo(() => {
    return {
      isVIP:
        beneficiary?.client_class?.slug === "memberClass_vip" ? true : false,
    };
  }, [JSON.stringify(beneficiary)]);

  if (isLoading)
    return (
      <div className="bg-white text-danger rounded-1 p-2">
        <MultiSkeleton length={1} />
      </div>
    );

  return (
    !isLoading &&
    beneficiary && (
      <Fragment>
        <div className="d-flex align-items-center">
          {beneficiary?.status?.id === 115 ? (
            <Badge
              color="purple"
              className="font-size-14 align-self-start ms-2"
            >
              Blacklisted
            </Badge>
          ) : null}
          {isVIP ? (
            <Badge
              color="primary"
              className="font-size-16 align-self-start ms-2"
              id="neon-badge"
            >
              {beneficiary?.client_class?.name}
            </Badge>
          ) : null}
          <ul className="list-unstyled hstack gap-1  ms-2 flex-wrap">
            <li>
              <Link
                className="badge bg-primary fw-semibold py-2 px-2"
                to={`${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}`}
                target="_blank"
              >
                {buttonsTitles.viewProfile}
              </Link>
            </li>
            <li>
              <button
                aria-label="Beneficiary Medical File"
                className="border-0 badge bg-primary fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.medicalFilesModal)}
              >
                {buttonsTitles.medicalFile}
              </button>
            </li>

            {/* networks */}
            {/* <li className={"d-md-flex d-none align-items-center gap-1"}>
              {beneficiary?.policy?.product?.product_networks?.length > 0 &&
                uniqBy(
                  beneficiary?.policy?.product?.product_networks,
                  "id"
                )?.map((productNetwork, index) => {
                  const isNetworkFoundInPlans =
                    beneficiary?.co_participations?.length > 0 &&
                    beneficiary?.co_participations?.find(
                      (plan) =>
                        plan?.benefit?.id === productNetwork?.benefit?.id &&
                        plan?.class?.id === productNetwork?.class?.id
                    )
                      ? true
                      : false;
                  return isNetworkFoundInPlans ? (
                    <div
                      className="badge bg-primary fw-semibold py-2 px-2"
                      key={`${productNetwork.id}-${productNetwork?.benefit?.id}-${index}`}
                      onClick={() => {
                        setIsModal(modalsStates.networkModal);
                        setNetwork({
                          id: productNetwork.id,
                          name: productNetwork?.network?.name,
                        });
                      }}
                    >
                      {productNetwork?.network?.name?.substring(0, 9) + "..."}
                    </div>
                  ) : null;
                })}
            </li> */}

            {/* Actions Dropdown  */}

            <li>
              <Dropdown
                isOpen={isActionMenuOpen}
                toggle={() => setIsActionMenuOpen(!isActionMenuOpen)}
                className="align-self-center"
              >
                <DropdownToggle
                  tag="button"
                  className="badge border-0 bg-primary fw-bold py-2 px-2 d-flex gap-2"
                  caret
                >
                  <span>{buttonsTitles.actions}</span>
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  <button
                    aria-label="Beneficiary FOC Spent"
                    className="dropdown-item"
                    onClick={() => setIsModal(modalsStates.focSpent)}
                  >
                    {buttonsTitles.focSpent}
                  </button>

                  <button
                    aria-label="Beneficiary FOC Spent"
                    className="dropdown-item"
                    onClick={() => setIsModal(modalsStates.fobSpent)}
                  >
                    {buttonsTitles.fobSpent}
                  </button>

                  {/* Medical Opinion Cases for this Claim */}
                  {claimId && (
                    <button
                      aria-label="Beneficiary FOC Spent"
                      className="dropdown-item"
                      onClick={() => setIsModal("MOCases")}
                    >
                      SMO Cases
                    </button>
                  )}
                  {/* Claim History */}

                  <button
                    className="dropdown-item"
                    aria-label={buttonsTitles.claimHistory}
                    onClick={() => setIsModal("CLAIM_HISTORY")}
                  >
                    {buttonsTitles.claimHistory}
                  </button>
                </DropdownMenu>
              </Dropdown>
            </li>

            {claimId ? (
              <li className="position-relative">
                <button
                  className="badge border-0 bg-primary fw-bold py-2 px-2"
                  onClick={() => setIsModal(modalsStates.chatModal)}
                >
                  {buttonsTitles.chat}
                  <ClaimChatNotification claim={claim} inClaimForm={true} />
                </button>
              </li>
            ) : null}

            {/* send wattsapp and Email buttons */}
            {claimId ? (
              <li>
                <Dropdown
                  isOpen={isMenuOpen}
                  toggle={() => setIsMenuOpen(!isMenuOpen)}
                  className="align-self-center"
                >
                  <DropdownToggle
                    tag="button"
                    className="badge border-0 bg-primary fw-bold py-2 px-2 d-flex gap-2"
                    caret
                  >
                    <span>{buttonsTitles.send}</span>
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <button
                      className="dropdown-item d-flex align-content-center gap-3"
                      onClick={() => {
                        setIsModal(modalsStates.whatAppModal);
                        setIsMenuOpen(false);
                      }}
                    >
                      <i className="bx bxl-whatsapp font-size-17" />
                      <span>{buttonsTitles.sendWhatsapp}</span>
                    </button>
                    <button
                      className="dropdown-item d-flex align-content-center gap-3"
                      onClick={() => {
                        setIsModal(modalsStates.emailModal);
                        setIsMenuOpen(false);
                      }}
                    >
                      <i className="bx bx-envelope font-size-16" />
                      <span>{buttonsTitles.sendEmail}</span>
                    </button>
                    <button
                      className="dropdown-item d-flex align-content-center gap-3"
                      onClick={() => {
                        setIsModal(modalsStates.smsModal);
                        setIsMenuOpen(false);
                      }}
                    >
                      <i className="bx bx-message-alt-dots font-size-16" />
                      <span>{buttonsTitles.sendSMS}</span>
                    </button>
                  </DropdownMenu>
                </Dropdown>
              </li>
            ) : null}

            {claimId && (
              <li className={"d-md-block d-sm-none d-none"}>
                <SourceTaskDropdown sourceId={claimId} sourceType="Claim" />
              </li>
            )}
          </ul>
        </div>

        {isModal === modalsStates.sepcialNotes && (
          <SpecialNotesModal
            show={isModal === modalsStates.sepcialNotes}
            onCloseClick={() => setIsModal(false)}
            beneficaryId={beneficiary?.id}
            beneficary={beneficiary}
          />
        )}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            resourceId={claimId}
          />
        )}

        {isModal === modalsStates.medicalFilesModal && (
          <MedicalFilesTableModal
            show={isModal === modalsStates.medicalFilesModal}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
            beneficiary={beneficiary}
            notInClaim={isChronicForm}
          />
        )}

        {isModal === modalsStates.focSpent && (
          <FocSpentModal
            show={isModal === modalsStates.focSpent}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
          />
        )}
        {isModal === modalsStates.fobSpent && (
          <FOBSpentModal
            show={isModal === modalsStates.fobSpent}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
            beneficiary={beneficiary}
          />
        )}

        {isModal === modalsStates.networkModal && (
          <NetworkContractsModal
            show={isModal === modalsStates.networkModal}
            onCloseClick={() => setIsModal(false)}
            networkId={network?.id}
            networkName={network?.name}
          />
        )}
        {isModal === modalsStates.whatAppModal && (
          <WhatssAppModal
            isModal={isModal === modalsStates.whatAppModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}
        {isModal === modalsStates.emailModal && (
          <SendEmailModal
            isModal={isModal === modalsStates.emailModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}
        {isModal === modalsStates.smsModal && (
          <SendSMSModal
            isModal={isModal === modalsStates.smsModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}

        {isModal === "MOCases" ? (
          <ClaimMOCasesModal
            claim={claim}
            isModal={isModal === "MOCases"}
            setIsModal={setIsModal}
          />
        ) : null}

        {/* Claim History modal */}
        {isModal === "CLAIM_HISTORY" && (
          <ClaimHistoryActionsModal
            isModal={isModal === "CLAIM_HISTORY"}
            setIsModal={setIsModal}
          />
        )}
      </Fragment>
    )
  );
};

export default ClaimsBreadcrumbAddons;

import { TableContainer } from "components/Common"
import { getAllBeneficaries } from "helpers/Production/Policies/beneficaries_helper"
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "reactstrap"
import { apiErrorrHandler, generateColumns, NotificationMessage } from "utils"
import { beneficaryListModuleId } from "utils/modulesIds"
import { getActions as onGetActions } from "store/actions"

const BeneficiaryDependencyModal = ({
  beneficiary,
  show,
  onCloseClick,
  beneficiaryId,
  t,
}) => {
  /*
 local states
*/
  const [showOptions, setShowOptions] = useState([])

  const [beneficiaries, setBeneficiaries] = useState([])
  const [pagination, setPagination] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetActions(beneficaryListModuleId))
  }, [])
  const actionFields = useSelector((state) => state?.Module?.actions?.fields)
  const moduleId = useSelector((state) => state?.Module?.actions?.id)

  // Header of the Table
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (actionFields) {
      setColumns(generateColumns(actionFields))
    }
  }, [actionFields, showOptions])

  const getBeneficiaries = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    ...rest
  }) => {
    try {
      setLoading(true)
      const res = await getAllBeneficaries({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        parent_id:
          beneficiary?.dependency?.slug === "beneficiary-principal"
            ? beneficiaryId
            : beneficiary?.group?.id,
        ...rest,
      })
      setBeneficiaries(res.items)
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  return (
    <Fragment>
      <Modal
        size="xl"
        isOpen={show}
        toggle={onCloseClick}
        backdrop="static"
        className="modal-fullscreen"
      >
        <div className="modal-header">
          {/* Modal Header Title */}
          <h5 className="modal-title mt-0" id="myModalLabel">
            <Fragment>{`Beneficiary: ${
              beneficiary?.dependency?.slug === "beneficiary-principal"
                ? beneficiary?.name
                : beneficiary?.group?.name
            }`}</Fragment>
          </h5>
          {/* close button on the top right section */}
          <button
            aria-label="Close Modal"
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
        </div>
        <div className="modal-body">
          <TableContainer
            columns={columns}
            data={beneficiaries}
            pagination={pagination}
            getData={getBeneficiaries}
            queryAddOn={{
              parent_id:
                beneficiary?.dependency?.slug === "beneficiary-principal"
                  ? beneficiaryId
                  : beneficiary?.group?.id,
            }}
            loading={loading}
            // for show / hide columns
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            fullColumns={generateColumns(actionFields)}
            moduleId={moduleId}
            removeActionsButtons={true}
            removeActionsColumns={true}
          />
        </div>
      </Modal>
    </Fragment>
  )
}

export default BeneficiaryDependencyModal

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";

//i18n
import { withTranslation } from "react-i18next";
import { convertNameToRoute, findDateDifference } from "utils";
import MultiSkeleton from "components/TP/Common/MultiSkeleton";
import { generalPaths } from "common/data/routePaths";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "store/actions";
import NotificationDropdownItem from "./NotificationDropdownItem";
import { staffTasksModuleID } from "utils/modulesIds";
import UpdateStaffTask from "pages/General/Tasks/UpdateStaffTask";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [isModal, setIsModal] = useState(false);

  // store the id of the resource for the notification
  const [resourceId, setResourceId] = useState(null);
  // store the module of the notification
  const [module, setModule] = useState(null);

  const dispatch = useDispatch();

  const { notifications, error, loading } = useSelector((state) => ({
    notifications: state.Notifications.notifications,
    error: state.Notifications.error,
    loading: state.Notifications.loading,
  }));

  useEffect(() => {
    if (notifications.length === 0) {
      dispatch(getNotifications());
    }
  }, [notifications.length]);

  // store the modules that come from the notification and it is form is pop up not page
  const popUpsModules = [staffTasksModuleID];

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {/* Number of Notifications */}
          {/* <span className="badge bg-danger rounded-pill">1</span> */}
          {notifications.length > 0 ? (
            // <span className="badge bg-danger rounded-pill">
            <span className="badge">{notifications.length}</span>
          ) : null}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link
                  to={generalPaths.dropdownNotifications}
                  className="small"
                  target="_blank"
                >
                  {props.t("View All")}
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "200px" }}>
            {loading ? <MultiSkeleton length={5} /> : null}
            {!loading &&
              notifications.length > 0 &&
              notifications.map((notification) => (
                <Fragment key={notification.id}>
                  {/* if the module is pop up module render div else render link */}
                  {popUpsModules.includes(notification?.module?.id) ? (
                    <div
                      className="text-reset notification-item"
                      onClick={() => {
                        setResourceId(notification.resource_id);
                        setModule(notification.module);
                        setIsModal(true);
                        setMenu(false);
                      }}
                    >
                      <NotificationDropdownItem notification={notification} />
                    </div>
                  ) : (
                    <Link
                      to={`/${convertNameToRoute(
                        notification.module.name
                      )}/edit/${notification.resource_id}`}
                      onClick={() => setMenu(false)}
                      target="_blank"
                      className="text-reset notification-item"
                    >
                      <NotificationDropdownItem notification={notification} />
                    </Link>
                  )}
                </Fragment>
              ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to={generalPaths.dropdownNotifications}
              target="_blank"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>

      {/* for task open update task pop up */}
      {isModal && module && module.id === staffTasksModuleID ? (
        <UpdateStaffTask
          isUpdateModal={isModal}
          setIsUpdateModal={setIsModal}
          staffTaskToUpdate={resourceId}
          setStaffTaskToUpdate={setResourceId}
          inTasksForm={false}
        />
      ) : null}
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

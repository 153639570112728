import { request } from "helpers/api_helper";
import { CHRONICFORM } from "helpers/url_helper";

/*
Chronic Forms
*/

// add new Chronic

// url
export const addChronic = (data) =>
  request({ url: CHRONICFORM, method: "POST", data });

// get all Chronic Forms with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllChronics = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: CHRONICFORM,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  });

// show Chronic
//url/{id}

export const showChronic = (chronicId) =>
  request({ url: `${CHRONICFORM}/${chronicId}`, method: "GET" });

// update Chronic
//url/{id}

export const updateChronic = (data) =>
  request({ url: `${CHRONICFORM}/${data.id}`, method: "PUT", data });

// mark unused Chronic
//url

export const markUnusedChronic = (data) =>
  request({ url: CHRONICFORM, method: "PATCH", data });

// delete Chronic
//url/{id}
export const deleteChronic = (chronicId) =>
  request({ url: `${CHRONICFORM}/${chronicId}`, method: "DELETE" });

// activate Chronic
//url/{id}/activate
export const activateChronic = (chronicId) =>
  request({ url: `${CHRONICFORM}/${chronicId}/activate`, method: "POST" });

// deactivate Chronic
//url/{id}/deactivate
export const deactivateChronic = (chronicId) =>
  request({
    url: `${CHRONICFORM}/${chronicId}/deactivate`,
    method: "POST",
  });

// Print Chronic Prescription
//url/{id}

export const printChronicPrescription = (params) =>
  request({ url: `${CHRONICFORM}/print`, method: "GET", params });

import React, { useMemo } from "react";
import moment from "moment";
import { previewDateFormat } from "constants/TPA";
import { Link } from "react-router-dom";
import { productionPaths } from "common/data/routePaths";

const MedicalHistoryDetails = ({ beneficiary }) => {
  const { product, memberRenewal } = useMemo(() => {
    let benefiProduct, beneficiaryPolicyRenewal;

    const beneficiaryContiuity = beneficiary?.continuity_year;
    const policyContiuity = beneficiary?.policy?.continuity_year;
    // subtract policy renewal from beneficiary renewal
    const continuityDiff = beneficiaryContiuity - policyContiuity;

    // benenficiary continuity

    if (beneficiary?.policy?.product) {
      benefiProduct = beneficiary?.policy?.product;
    }
    if (
      beneficiary?.co_participations?.length > 0 &&
      beneficiary?.co_participations[0]?.product
    ) {
      benefiProduct = beneficiary?.co_participations[0]?.product;
    } else {
      benefiProduct = null;
    }

    // member renewal calculations
    // if the benficiary continuity > policy continuity => show the difference
    // else show it as zero
    if (beneficiaryContiuity > policyContiuity) {
      beneficiaryPolicyRenewal = beneficiaryContiuity - continuityDiff;
    } else {
      beneficiaryPolicyRenewal = 0;
    }

    return {
      product: benefiProduct,
      memberRenewal: beneficiaryPolicyRenewal,
    };
  }, [JSON.stringify(beneficiary)]);

  return (
    <div className="mb-2">
      <ul className="list-unstyled hstack gap-2 flex-wrap">
        {/* Beneficiary Status */}
        {beneficiary?.status ? (
          <li className="d-flex gap-2 align-items-center">
            <i className="mdi mdi-police-badge-outline font-size-14 text-primary"></i>
            <span>{beneficiary?.status?.name}</span>
          </li>
        ) : null}

        {/* Beneficiary Pin Number */}
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-id-card font-size-14 text-primary"></i>
          <span>
            Pin Number:{" "}
            {beneficiary?.pin_number ? beneficiary?.pin_number : "N/A"}
          </span>
        </li>

        {/* Beneficiary Date of Birth */}

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-cake font-size-14 text-primary"></i>
          <span>
            {moment(beneficiary?.birth_date).format(previewDateFormat)}
            {`(${moment().diff(beneficiary?.birth_date, "year")} Y)`}
          </span>
        </li>
        {/* Beneficiary Code */}

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bxs-user-pin font-size-14 text-primary"></i>
          <span>{`${beneficiary?.gender?.name?.slice(0, 1).toUpperCase()} ${
            beneficiary?.martial_status
              ? `| ${beneficiary?.martial_status?.name}`
              : ""
          }`}</span>
        </li>

        {/* Coparticipations Plans */}

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-money font-size-14 text-primary"></i>
          <span>
            Plans:{" "}
            {beneficiary &&
            beneficiary.co_participations &&
            beneficiary.co_participations.length > 0 ? (
              beneficiary.co_participations.map((item, index) => (
                <span key={item.id} className="font-size-12">
                  {item.name}
                  {index === beneficiary?.co_participations?.length - 1
                    ? ""
                    : " | "}
                </span>
              ))
            ) : (
              <span>N/A</span>
            )}
          </span>
        </li>
        {/* Policy Start Date to Policy End Date */}
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-calendar font-size-14 text-primary"></i>
          <span>
            <b>From</b>{" "}
            {beneficiary?.policy_effective_date
              ? moment(beneficiary?.policy_effective_date).format("DD-MM-YYYY")
              : "N/A"}{" "}
            <b>To</b>{" "}
            {beneficiary?.policy?.stop_date
              ? moment(beneficiary?.policy?.stop_date).format("DD-MM-YYYY")
              : "N/A"}
          </span>
        </li>

        {/* Beneficiary policy */}
        {beneficiary?.policy?.reference ? (
          <li className="d-flex gap-2 align-items-center">
            <i className="mdi mdi-police-badge-outline font-size-14 text-primary"></i>
            {/* link to the view page of product */}
            <Link
              to={`${productionPaths.policy}/view/${beneficiary?.policy?.id}`}
              target="_blank"
              className="text-primary fw-bold"
            >
              {beneficiary?.policy?.reference} | {beneficiary?.policy?.name}
            </Link>
          </li>
        ) : null}

        {/* Beneficiary Product */}
        {product ? (
          <li className="d-flex gap-2 align-items-center">
            <i className="bx bx-shield-quarter font-size-14 text-primary"></i>
            {/* link to the view page of product */}
            <Link
              to={`${productionPaths.products}/view/${product?.id}`}
              target="_blank"
              className="text-primary fw-bold"
            >
              {product?.name}
            </Link>
          </li>
        ) : null}
        {/* Beneficiary Has Gr */}
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-user font-size-14 text-primary"></i>
          <span>Has Gr: {beneficiary?.has_gr ? "Yes" : "No"}</span>
        </li>
        {/* Beneficiary  Gr  days*/}
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bxs-calculator font-size-14 text-primary"></i>
          <span>Gr Days: {beneficiary?.used_gr_days}</span>
        </li>

        {/* is Continuity  */}

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-user font-size-14 text-primary"></i>
          <span>
            Has Continuity: {beneficiary?.is_continuity ? "Yes" : "No"}
          </span>
        </li>
        {/* Continuity  years */}

        {/* <li className="d-flex gap-2 align-items-center">
          <i className="bx bxs-calculator font-size-14 text-primary"></i>
          <span>
            Continuity Years:{" "}
            {beneficiary?.continuity_year -
              beneficiary?.policy?.continuity_year}
          </span>
        </li> */}
        {/* Broker Code */}

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bxs-group font-size-14 text-primary"></i>
          <span>Broker Name: {beneficiary?.policy?.broker?.name}</span>
        </li>

        {/* Mobile */}

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-mobile font-size-14 text-primary"></i>
          <span>
            Mobile: {beneficiary?.mobile ? beneficiary?.mobile : "N/A"}{" "}
          </span>
        </li>
        {/* Beneficiary Join Date*/}

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-calendar font-size-14 text-primary"></i>
          <span>
            Continuity Since:{" "}
            {beneficiary?.join_date
              ? moment(beneficiary?.join_date).format(previewDateFormat)
              : "N/A"}{" "}
          </span>
        </li>
        {/* Member Renewal Years */}
        {/* <li className="d-flex gap-2 align-items-center">
          <i className="mdi mdi-alpha-r-box font-size-14 text-primary"></i>
          <span>Member Renewal: {memberRenewal} </span>
        </li> */}
        {/*previous_insurance_starting  */}
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-calendar font-size-14 text-primary"></i>
          <span>
            Inception Date:{" "}
            {beneficiary?.policy?.previous_insurance_starting
              ? moment(beneficiary?.policy?.previous_insurance_starting).format(
                  previewDateFormat
                )
              : "N/A"}{" "}
          </span>
        </li>

        {/* Policy Renewals*/}

        <li className="d-flex gap-2 align-items-center">
          <i className="mdi mdi-alpha-r-box font-size-14 text-primary"></i>
          <span>
            Master Contract Renewal:{" "}
            {beneficiary?.policy?.continuity_year
              ? beneficiary?.policy?.continuity_year
              : "N/A"}{" "}
          </span>
        </li>
        {/* Previous Insurer*/}
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-home-circle font-size-14 text-primary"></i>
          <span>
            Previous Insurer:{" "}
            {beneficiary?.previous_insurer
              ? beneficiary?.previous_insurer?.name
              : "N/A"}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default MedicalHistoryDetails;

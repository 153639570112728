import { useDispatch } from "react-redux"
import { openModal } from "store/actions"

const useOpenStateModal = () => {
  const dispatch = useDispatch()

  const showModal = ({
    title,
    message,
    confirmButtonTitle = undefined,
    includeTwoButtons = false,
    confirmCallback = undefined,
    closeCallback = undefined,
  }) => {
    dispatch(
      openModal({
        title,
        message,
        confirmButtonTitle,
        twoButtons: includeTwoButtons,
        confirmCallback,
        closeCallback,
      })
    )
  }

  return {
    showModal,
  }
}

export default useOpenStateModal

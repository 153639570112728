import { request } from "../../api_helper"
import { POLICIESENDORESMENT, POLICIES } from "../../url_helper"
/*
Policiess
*/

// add new Policy

// url
export const addPolicy = data =>
  request({ url: POLICIES, method: "POST", data })

// get all Policys with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllPolicies = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: POLICIES,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// get all Policy Endorsement with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllPolicyEndrosements = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
  // view,
}) =>
  request({
    url: POLICIESENDORESMENT,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      // sort_by: sort_by ? sort_by : "id",
      // sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
      // view,
    },
  })

// show Policy endoresment

export const showEndorsement = endorsementId =>
  request({ url: `${POLICIESENDORESMENT}/${endorsementId}`, method: "GET" })

// show Policy
//url/{id}

export const showPolicy = policyId =>
  request({ url: `${POLICIES}/${policyId}`, method: "GET" })

// update Policy
//url/{id}

export const updatePolicy = data =>
  request({ url: `${POLICIES}/${data.id}`, method: "PUT", data })

// update Policy Settings
//url/{policyId}/settings

export const updatePolicySettings = data =>
  request({
    url: `${POLICIES}/${data.policy_id}/settings`,
    method: "PATCH",
    data,
  })

// delete Policy
//url/{id}
export const deletePolicy = policyId =>
  request({
    url: `${POLICIES}/${policyId}`,
    method: "DELETE",
  })

// activate Policy
//url/activate/{id}
export const activatePolicy = policyId =>
  request({
    url: `${POLICIES}/${policyId}/activate`,
    method: "POST",
  })

// deactivate Policy
//url/deactivate/{id}
export const deactivatePolicy = policyId =>
  request({
    url: `${POLICIES}/${policyId}/deactivate`,
    method: "POST",
  })

// renewal Policy
//url/{policyId}/renew
export const renewalPolicy = (policyId, data) =>
  request({
    url: `${POLICIES}/${policyId}/renew`,
    method: "POST",
    data,
  })

// Get Policy dependencies
//url/{id}/dependencies

export const getPolicyDependencies = policyId =>
  request({ url: `${POLICIES}/${policyId}/dependencies`, method: "GET" })

// policy premiums
export const getPolicyPremiums = (policyId, params) =>
  request({
    url: `${POLICIES}/${policyId}/premiums`,
    method: "GET",
    params,
  })

export const validateInValidatePolicy = (policyId, data) =>
  request({
    url: `${POLICIES}/${policyId}/validations`,
    method: "POST",
    data,
  })

// Get Policy Beneficiaries
export const getAllPolicyBeneficiaries = (policyId, params) =>
  request({
    url: `${POLICIES}/${policyId}/beneficiaries`,
    method: "GET",
    params,
  })

// calculate renewal
// policyURL/policyId/calculate-renewal
export const calculateRenewal = (policyId, data) =>
  request({
    url: `${POLICIES}/${policyId}/calculate-renewal`,
    method: "POST",
    data,
  })

// Update Status (white list or Black Lits) Re-insurer
//url/{id}/statuses
export const updatePolicyStatus = data =>
  request({
    url: `${POLICIES}/${data.id}/statuses`,
    method: "POST",
    data,
  })

//special import
export const uploadSpecialBeneficiaries = (policyId, data) =>
  request({
    url: `${POLICIES}/${policyId}/beneficiary-import-nextcare`,
    method: "POST",
    data,
  })
//special import
export const importPolicies = data =>
  request({
    url: `${POLICIES}/import`,
    method: "POST",
    data,
  })

export const importGeneralPolices = data =>
  request({
    url: `${POLICIES}/general-business-import`,
    method: "POST",
    data,
  })

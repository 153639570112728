import { request } from "helpers/api_helper"
import * as url from "helpers/url_helper"

/*
Claim Forms
*/

// add new Claim

// url
export const addClaim = (data) =>
  request({ url: url.CLAIMFORM, method: "POST", data })

// get all Claim Forms with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllClaims = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: url.CLAIMFORM,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  })

// show Claim
//url/{id}

export const showClaim = (claimFormId) =>
  request({ url: `${url.CLAIMFORM}/${claimFormId}`, method: "GET" })

// update Claim
//url/{id}

export const updateClaim = (data) =>
  request({ url: `${url.CLAIMFORM}/${data.id}`, method: "PUT", data })

// mark unused Claim
//url

export const markUnusedClaim = (data) =>
  request({ url: url.CLAIMFORM, method: "PATCH", data })

// delete Claim
//url/{id}
export const deleteClaim = (claimFormId) =>
  request({ url: `${url.CLAIMFORM}/${claimFormId}`, method: "DELETE" })

// activate Claim
//url/{id}/activate
export const activateClaim = (claimFormId) =>
  request({ url: `${url.CLAIMFORM}/${claimFormId}/activate`, method: "POST" })

// deactivate Claim
//url/{id}/deactivate
export const deactivateClaim = (claimFormId) =>
  request({
    url: `${url.CLAIMFORM}/${claimFormId}/deactivate`,
    method: "POST",
  })

// copy claim
//url/copy/{id}
export const copyClaim = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/copy`,
    method: "POST",
    data,
  })

// Upload OCR File
export const upoladClaimOCR = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/upload-test`,
    method: "POST",
    data,
  })
// Creat Claim OTP
export const createOTP = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/otp`,
    method: "POST",
    data,
  })

// assign claim
export const assignClaim = (data) =>
  request({
    url: url.CLAIMASSIGN,
    method: "POST",
    data,
  })

// Send Email
// body:{
// emails:["email1","email 2"],
//path:"claims/inPatient"
//}
export const sendClaimEmail = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/send-email`,
    method: "POST",
    data,
  })

// Creat Claim OTP
export const validateTOB = (data) =>
  request({
    url: url.VALIDATETOB,
    method: "POST",
    data,
  })
// Creat Claim Task
export const addClaimStaffTask = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/staff-tasks`,
    method: "POST",
    data,
  })

// get Claim Tasks
export const getClaimStaffTasks = (
  claimId,

  {
    page,
    per_page,
    sort_by,
    sort_type,
    search_keys,
    search_values,
    search_value,
    ...rest
  }
) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/staff-tasks`,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  })

// Creat Claim Reminder

export const addClaimReminder = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/reminders`,
    method: "POST",
    data,
  })

// get all reminders

export const getReminders = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  sourceId,
  userId,
  ...rest
}) =>
  request({
    url: `${url.REMINDERS}`,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

//get all claims history
export const getAllClaimsHistory = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: url.CLAIMHISTORY,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  })

// show claims history
export const showClaimHistory = (claimId) =>
  request({ url: `${url.CLAIMHISTORY}/${claimId}`, method: "GET" })

// Validate PCI RULE
export const validatePCIRule = (data) =>
  request({
    url: `${url.CLAIMFORM}/validate-pci-engine`,
    method: "POST",
    data,
  })

// Proforma Claims Index
//get all claims Proforma
export const getAllClaimsProformas = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  claimId,
  ...rest
}) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/proformas`,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  })
// Bulk Proforma Claim
export const bulkProformaClaim = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/proformas/bulk`,
    method: "PUT",
    data,
  })

import React, { useEffect, useMemo, useState } from "react";
import { DatePickerInput, FormModal } from "components/TP/Common/Forms";
// translation
import { withTranslation } from "react-i18next";
// helper functions

import {
  calculatePremiums,
  getPremiums,
  savePremium,
} from "helpers/Production/Policies/beneficaries_helper";
import { NotificationMessage, apiErrorrHandler } from "utils";
import { Alert } from "reactstrap";
import { isNaN } from "lodash";
import {
  formatedTodayData,
  formatNumber,
  reduceArray,
  roundNumber,
} from "utils";
import { StickyHeaderTable } from "../Tables";
import { staticFieldLabels } from "common/data";

const PremiumModal = ({
  isPremiumModal,
  setIsPremiumModal,
  editable,
  beneficaryId,
  policy,
  t,
}) => {
  const [error, setError] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [premium, setPremium] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(
    policy && policy?.start_date ? policy.start_date : formatedTodayData()
  );

  const [tableData, setTableData] = useState([]);
  const tableHeaders = useMemo(() => {
    const policyCurrency = policy?.currency?.symbol;
    if (editable) {
      return [
        "Net Premium " + policyCurrency,
        "Gross Premium " + policyCurrency,
        "Discount " + policyCurrency,
        "Over Premium " + policyCurrency,
        "Issuing Fees " + policyCurrency,
        "Premium Difference " + policyCurrency,
        "Tax " + policyCurrency,
        "Total " + policyCurrency,
      ];
    } else {
      return [
        "#",
        "Beneficiary",
        "Net Premium " + policyCurrency,
        "Gross Premium " + policyCurrency,
        "Discount " + policyCurrency,
        // "Issuing Fees " + policyCurrency,
        "Over Premium " + policyCurrency,
        "Premium Difference " + policyCurrency,
        "AMB Premium " + policyCurrency,
        "IP Premium " + policyCurrency,
        "PM Premium " + policyCurrency,
        "DV Premium " + policyCurrency,
        "Insured Days",
        // "Tax " + policyCurrency,
        "Total " + policyCurrency,
      ];
    }
  }, [editable, JSON.stringify(policy)]);

  // save premium
  const handleSavePremium = async (beneficaryId) => {
    setIsSaving(true);
    try {
      const response = await savePremium(beneficaryId, {
        discount_amount:
          premium && premium.discount_amount ? premium.discount_amount : 0,
        adjustment_amount:
          premium && premium.adjustment_amount ? premium.adjustment_amount : 0,

        effective_date: effectiveDate ? effectiveDate : formatedTodayData(),
        issuing_fees:
          premium && premium.issuing_fees ? premium.issuing_fees : 0,
      });

      setPremium({
        ...response.item,
        discount_amount: response.item.discount_amount.toFixed(2),
        issuing_fees: "",
        sub_total:
          +response.item.price +
          +response.item.loading_amount +
          +response.item.tpa_fees_amount +
          +response.item.commission_amount +
          +response.item.other_amount,
      });

      NotificationMessage("success", "Saved Successfully");
      setTimeout(() => {
        setIsPremiumModal(false);
        window.location.reload(false);
      }, 2000);
      setError("");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  // calculate Premium

  const calculateBeneficiaryPremiums = async (beneficaryId) => {
    setIsLoading(true);
    try {
      const response = await calculatePremiums({
        discount_amount:
          premium && premium.discount_amount ? premium.discount_amount : 0,
        adjustment_amount:
          premium && premium.adjustment_amount ? premium.adjustment_amount : 0,
        beneficaryId: beneficaryId,
        effective_date: effectiveDate ? effectiveDate : formatedTodayData(),
        issuing_fees:
          premium && premium.issuing_fees ? premium.issuing_fees : 0,
      });
      setPremium({
        ...response.item,
        discount_amount: response.item.discount_amount.toFixed(2),
        sub_total:
          +response.item.price +
          +response.item.loading_amount +
          +response.item.tpa_fees_amount +
          +response.item.commission_amount +
          +response.item.other_amount,
      });
      NotificationMessage("success", "Calculated Successfully");
      setError("");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Index Premium

  const getBeneficiaryPremiums = async (beneficaryId) => {
    setIsLoading(true);
    try {
      const response = await getPremiums(beneficaryId, { has_children: 1 });
      const resPremiums = response?.item;
      setTableData(resPremiums);
      setError("");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (beneficaryId && !editable) {
      getBeneficiaryPremiums(beneficaryId);
    }
  }, [beneficaryId]);

  return (
    <FormModal
      show={isPremiumModal}
      onCloseClick={() => {
        setIsPremiumModal(false);
        setError("");
      }}
      onSaveClick={() => {
        editable && handleSavePremium(beneficaryId);
      }}
      includeComputeButton={editable ? true : false}
      onComputeClick={() => calculateBeneficiaryPremiums(beneficaryId)}
      hideSaveButton={!premium ? true : false}
      modalTitle={t("Premium Calcualtion")}
      isSaving={isSaving || isLoading}
      disableSave={editable ? (isSaving || discountError ? true : false) : true}
      modalSize="xl"
      noFooter={!editable}
      notCentered={true}
    >
      {error && <Alert color="danger">{error}</Alert>}
      {editable && (
        <DatePickerInput
          label={staticFieldLabels.effectiveData}
          value={effectiveDate}
          onChange={(date, dateStr, instance) => setEffectiveDate(dateStr)}
          removeDisable={true}
          minDate={policy?.start_date}
          maxDate={policy?.stop_date}
        />
      )}
      {/* Table */}

      <StickyHeaderTable
        columns={tableHeaders}
        isLoading={isLoading}
        noData={(!editable && tableData.length === 0) || (editable && !premium)}
        loadingRows={5}
      >
        {!editable &&
          tableData.length > 0 &&
          tableData.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td className="text-capitalize">{item.beneficiary?.name}</td>
              {/* Net PReimum */}
              <td>{formatNumber(item?.price)}</td>
              {/* gross premium */}
              <td>{formatNumber(item?.total_price)}</td>
              {/* discount amount */}
              <td>{formatNumber(item?.discount_amount)}</td>
              {/* issuing fees */}
              {/* <td>{formatNumber(item?.issuing_fees)}</td> */}
              {/* adjustment amount (over premium) */}
              <td>{formatNumber(item?.adjustment_amount)}</td>
              {/* premium difference */}
              <td>{formatNumber(item?.premium_difference)}</td>
              {/* AMB premium */}
              <td>
                {formatNumber(
                  item?.beneficiary
                    ?.amb_beneficiary_product_co_participation_price
                )}
              </td>
              {/* IP premium */}
              <td>
                {formatNumber(
                  item?.beneficiary
                    ?.in_beneficiary_product_co_participation_total_price
                )}
              </td>
              {/* PM premium */}
              <td>
                {formatNumber(
                  item?.beneficiary
                    ?.pm_beneficiary_product_co_participation_price
                )}
              </td>
              {/* DV premium */}
              <td>
                {formatNumber(
                  item?.beneficiary
                    ?.dv_beneficiary_product_co_participation_price
                )}
              </td>
              <td>{item?.insured_days}</td>
              {/* Tax amount */}
              {/* <td>{formatNumber(item?.tax_amount)}</td> */}
              {/* Total */}
              <td>{formatNumber(item?.total_price + +item?.tax_amount)}</td>
            </tr>
          ))}
        {editable && premium && (
          <tr>
            <td>{roundNumber(premium?.price)}</td>
            <td>{roundNumber(premium.sub_total || 0)}</td>
            {/* discount amount */}
            <td>
              <input
                id=""
                name=""
                type="number"
                min="0"
                className={`editable-table-input ${
                  discountError ? "border-danger" : ""
                }`}
                value={premium.discount_amount}
                onChange={(e) => {
                  const numValue = e.target.valueAsNumber;
                  setPremium({
                    ...premium,
                    discount_amount: numValue < 0 ? numValue * -1 : numValue,
                  });

                  if (numValue > roundNumber(premium?.loading_amount)) {
                    setDiscountError(
                      `Discount Cann’t be greater than ${roundNumber(
                        premium?.loading_amount
                      )}`
                    );
                  } else {
                    setDiscountError("");
                  }
                }}
              />
              {discountError ? (
                <span className="text-danger font-size-10">
                  {discountError}
                </span>
              ) : null}
            </td>

            {/* Adjustment amount */}
            <td>
              <input
                id=""
                name=""
                type="number"
                min="0"
                className="editable-table-input"
                value={premium.adjustment_amount}
                onChange={(e) => {
                  setPremium({
                    ...premium,
                    adjustment_amount:
                      e.target.valueAsNumber < 0
                        ? e.target.valueAsNumber * -1
                        : e.target.valueAsNumber,
                  });
                }}
              />
            </td>

            {/* Issuing Fees */}
            <td>
              <input
                id=""
                name=""
                type="number"
                min="0"
                className="editable-table-input"
                value={premium.issuing_fees}
                onChange={(e) =>
                  setPremium({
                    ...premium,
                    issuing_fees:
                      e.target.valueAsNumber < 0
                        ? e.target.valueAsNumber * -1
                        : e.target.valueAsNumber,
                  })
                }
              />
            </td>

            {/* Vat */}
            <td>{premium.tax_amount || 0}</td>
            {/* total  => (subtotal + VAT) - discount */}
            <td>
              {premium.tax_amount
                ? (
                    premium.sub_total +
                    premium.adjustment_amount +
                    premium.issuing_fees +
                    premium.tax_amount -
                    premium.discount_amount
                  ).toFixed(2)
                : (
                    premium.sub_total +
                    premium.adjustment_amount +
                    premium.issuing_fees +
                    0 -
                    premium.discount_amount
                  ).toFixed(2)}
            </td>
          </tr>
        )}
        {!editable && (
          <tr>
            {/* 2 empty td */}
            <td colSpan={2}></td>
            {/* price */}
            <td>{reduceArray(tableData, "price")}</td>
            {/* total price */}
            <td>{reduceArray(tableData, "total_price")}</td>
            {/* discount amount */}
            <td>{reduceArray(tableData, "discount_amount")}</td>
            {/* isssuing fees */}
            {/* <td>{reduceArray(tableData, "issuing_fees")}</td> */}
            {/* adjustment amount */}
            <td>{reduceArray(tableData, "adjustment_amount")}</td>
            {/* 5 empty td */}
            <td colSpan={6}></td>
            {/* tax amount */}
            {/* <td>{reduceArray(tableData, "tax_amount")}</td> */}
            {/* Total  */}
            <td>
              {formatNumber(
                tableData.reduce(
                  (total, item) => total + item.total_price + +item?.tax_amount,
                  0
                )
              )}
            </td>
          </tr>
        )}
      </StickyHeaderTable>
    </FormModal>
  );
};

export default withTranslation()(PremiumModal);

import { buttonsTitles, endorsementTypes } from "common/data"
import { TableContainer } from "components/Common"
import { SingleRowAction } from "components/TP/Common/Tables"
import { previewDateFormat } from "constants/TPA"
import { getAllPolicyEndrosements } from "helpers/Production/Policies/policies_helper"
import { isNull } from "lodash"
import moment from "moment"
import React, { Fragment, useMemo, useState } from "react"
import { Modal } from "reactstrap"
import { apiErrorrHandler, isItemObject, NotificationMessage } from "utils"

const EndorsementHistorymodal = ({
  beneficiary,
  show,
  onCloseClick,
  beneficiaryId,
  policyId,
  t,
}) => {
  // desturctuere endorsement Types
  const { memberCancel: deletion, policyCancel: cancellation } =
    endorsementTypes

  /*
 local states
*/

  // storing the columns that user want to hide
  const [showOptions, setShowOptions] = useState([])

  const [policyEndorsements, setPolicyEndorsements] = useState([])

  // const [isAlertModal, setIsAlertModal] = useState(false)
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const [isModal, setIsModal] = useState(false)

  // check if the endorsment is type deletion

  const isDeletionEndorsment = (type) => {
    return type?.id === deletion.id ? true : false
  }
  const isCancellationEndorsment = (type) => {
    return type?.id === cancellation.id ? true : false
  }

  // format values based on types
  const formatValue = (value) => {
    let formattedValue
    if (moment(value, "YYYY-MM-DD", true).isValid()) {
      formattedValue = moment(value).format(previewDateFormat)
    } else if (isNull(value)) {
      formattedValue = ""
    } else if (value === 0) {
      formattedValue = "No"
    } else if (value === 1) {
      formattedValue = "Yes"
    } else if (Array.isArray(value) && value.length > 0) {
      formattedValue = value.join(", ")
    } else if (isItemObject(value)) {
      formattedValue = Object.entries(value).map(
        ([key, value]) => `${key}: ${value}`
      )
    } else {
      formattedValue = value
    }

    return formattedValue
  }
  // Header of the Table
  const columns = useMemo(
    () => [
      {
        Header: t("date"),
        accessor: "created_at",
        filterable: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          const CreatedAtDate = cellProps.row.original.created_at
          return <span>{moment(CreatedAtDate).format(previewDateFormat)}</span>
        },
      },
      {
        Header: t("effective date"),
        accessor: "effective_date",
        filterable: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          const effectiveDate = cellProps.row.original.effective_date
          return <span>{moment(effectiveDate).format(previewDateFormat)}</span>
        },
      },
      {
        Header: t("username"),
        accessor: "created_by.name",
        filterable: true,
        disableSortBy: true,
      },

      {
        Header: t("type"),
        accessor: "type.name",
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: t("old data"),
        accessor: "old_value",
        filterable: true,
        disableSortBy: true,

        Cell: (cellProps) => {
          const oldValues = cellProps.row.original.old_value
          const endorsementType = cellProps.row.original.type

          return !oldValues
            ? ""
            : Array.isArray(oldValues)
            ? oldValues.map((oldvalue, index) => (
                <span className="d-block" key={`${oldvalue}-${index}`}>
                  {oldvalue}
                </span>
              ))
            : Object.entries(oldValues)?.length > 0
            ? Object.entries(oldValues).map(([key, value], index) => (
                <span
                  className="d-block text-capitalize"
                  key={`${value}-${index}`}
                >
                  {key.replace("_", " ")}: {formatValue(value)}
                </span>
              ))
            : isDeletionEndorsment(endorsementType) ||
              isCancellationEndorsment(endorsementType)
            ? ""
            : oldValues
        },
      },
      {
        Header: t("endorsement details"),
        accessor: "new_value",
        filterable: true,
        disableSortBy: true,

        Cell: (cellProps) => {
          const newValues = cellProps.row.original.new_value
          return !newValues
            ? ""
            : Array.isArray(newValues)
            ? newValues.map((newValue, index) => (
                <span className="d-block" key={`${newValue}-${index}`}>
                  {newValue}
                </span>
              ))
            : Object.entries(newValues).map(([key, value], index) => (
                <span
                  className="d-block text-capitalize"
                  key={`${value}-${index}`}
                >
                  {key.replace("_", " ")}: {formatValue(value)}
                </span>
              ))
        },
      },
      {
        Header: t("new premium"),
        accessor: "beneficiary_premium.total_price",
        filterable: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          const data = cellProps.row.original?.beneficiary_premium
          const endorsementType = cellProps.row.original?.type

          return (
            <Fragment>
              {/* if type is deletinon don’t show new premium */}
              {isDeletionEndorsment(endorsementType) ? (
                ""
              ) : (
                <Fragment>
                  {data?.total_price ? data?.policy?.currency?.symbol : ""}
                  {data?.total_price}{" "}
                </Fragment>
              )}
            </Fragment>
          )
        },
      },
      {
        Header: t("old premium"),
        accessor: "beneficiary_premium.previous.total_price",
        filterable: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          const data = cellProps.row.original?.beneficiary_premium
          return (
            <Fragment>
              {data?.previous?.total_price
                ? data?.policy?.currency?.symbol
                : ""}
              {data?.previous?.total_price}{" "}
            </Fragment>
          )
        },
      },
      {
        Header: t("Actions"),
        accessor: "actions",
        disableFilters: true,
        Cell: (cellProps) => {
          const row = cellProps.row.original
          return (
            <Fragment>
              <SingleRowAction
                to={`/policy-endorsement/print/${row.id}/${policyId}`}
                target="_blank"
                iconClass="mdi mdi-cloud-print-outline"
                actionName={"print"}
                record={row}
              />
            </Fragment>
          )
        },
      },
    ],
    []
  )

  const getPolicyEndorsements = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    beneficiary_id,
    policy_id,
  }) => {
    try {
      setLoading(true)
      const res = await getAllPolicyEndrosements({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        beneficiary_id,
        policy_id: policy_id && policy_id !== "undefined" ? policy_id : "",
      })
      setPolicyEndorsements(res.items)
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  return (
    <Fragment>
      <Modal
        size="xl"
        isOpen={show}
        toggle={onCloseClick}
        backdrop="static"
        className="modal-fullscreen"
      >
        <div className="modal-header">
          {/* Modal Header Title */}
          <h5 className="modal-title mt-0" id="myModalLabel">
            <Fragment>
              {`${buttonsTitles.endorsementHistory}: ${beneficiary?.name}`}
            </Fragment>
          </h5>
          {/* close button on the top right section */}
          <button
            aria-label="Close Modal"
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
        </div>
        <div className="modal-body">
          <TableContainer
            columns={columns}
            data={policyEndorsements}
            // addPageLink={claimPaths.claimValidation}
            pagination={pagination}
            getData={getPolicyEndorsements}
            removeActionsColumns={true}
            loading={loading}
            //   addOnButtons={addOnButtons}
            queryAddOn={{
              beneficiary_id: beneficiaryId,
              policy_id: policyId,
            }}
            // for show / hide columns
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            fullColumns={columns}
            sheetName="Policy Endorsement"
            deleteFunc={() => {}}
            activateFunc={() => {}}
            deactivateFunc={() => {}}
            handleClickUpdate={() => {}}
            basicTable={true}
          />
        </div>
      </Modal>
    </Fragment>
  )
}

export default EndorsementHistorymodal

import { TableSkeleton } from "components/Common";
import React from "react";
import { NoRecords } from ".";
import { isString } from "lodash";

const StickyHeaderTable = ({
  isLoading,
  columns,
  prefixHeader,
  noData,
  children,
  loadingRows = 15,
  isVertical = false,
  isFirstHeadHidden = false,
}) => {
  return (
    <div
      className="table-responsive react-table mt-3"
      style={{ overflow: "auto", display: "block" }}
    >
      <table
        className={`table table-hover ${isVertical ? "vertical-table" : ""}`}
        style={{
          position: "relative",
          height: isLoading && `${loadingRows * 37}px`,
        }}
      >
        <thead
          className="table-light table-nowrap sticky-header"
          id="__FPSC_ID_1_1683283486344"
        >
          <tr>
            {prefixHeader ? prefixHeader : null}
            {columns.map((column, index) => (
              <th
                data-toggle="tooltip"
                title={column?.tooltip ? column?.tooltip : ""}
                key={isString(column) ? column : column.id}
                className={`${
                  index === 0 && isFirstHeadHidden ? "opacity-0 pt-2" : ""
                }`}
              >
                {isString(column) ? column : column.label}
              </th>
            ))}
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td
                colSpan={prefixHeader ? columns?.length + 1 : columns?.length}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                  }}
                >
                  <TableSkeleton loadingRows={loadingRows} />
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {noData ? (
              <tr>
                <td
                  colSpan={
                    prefixHeader ? columns.length + 3 : columns.length + 2
                  }
                >
                  <NoRecords />
                </td>
              </tr>
            ) : (
              children
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default StickyHeaderTable;

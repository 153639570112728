import * as XLSX from "xlsx";
import { flattenObject } from "./smallUtils";
import moment from "moment";

export function exportToExcel({ headers, data, filename }) {
  // Flatten and process data
  const flattenObjectData = data
    .map((item) => flattenObject(item))
    .map((row) => {
      let modifiedRow = Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          let dateOnlyFormat = "YYYY-MM-DD"; // Format: 2025-03-10
          let dateTimeFormats = [
            "YYYY-MM-DD HH:mm:ss", // Format: 2025-03-10 11:00:52
            "DD-MM-YYYY hh:mm A", // Format: 10-03-2025 11:00 AM
          ];

          if (moment(value, dateOnlyFormat, true).isValid()) {
            return [key, moment(value, dateOnlyFormat).toDate()]; // Store as Date (No Time)
          } else if (moment(value, dateTimeFormats, true).isValid()) {
            return [key, moment(value, dateTimeFormats).toDate()]; // Store as DateTime
          }

          return [key, value];
        })
      );

      return {
        ...modifiedRow,
        is_active: modifiedRow.is_active === 1 ? "Yes" : "No",
      };
    });

  // Map data to include only specified headers
  const filteredData = flattenObjectData.map((row) =>
    headers.reduce((acc, { label, key }) => {
      acc[label] = row[key];
      return acc;
    }, {})
  );

  // Create worksheet with proper date handling
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    cellDates: true, // Ensure Excel recognizes Dates
  });

  // Apply correct formatting only to actual date-time columns
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
      const cell = worksheet[cellAddress];

      if (cell && cell.v instanceof Date) {
        if (moment(cell.v).format("HH:mm:ss") === "00:00:00") {
          // Date only (No Time)
          cell.z = "dd/mm/yyyy";
        } else {
          // Date with Time
          cell.z = "dd/mm/yyyy hh:mm AM/PM";
        }
      }
    }
  }

  // Create and save workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}_${new Date().toISOString()}.xlsx`);
}

/* Paths */
import { generalPaths } from "common/data/routePaths";
import { lazy } from "react";

/*
Components
*/

const NotificationsLogs = lazy(() =>
  import("pages/General/Notifications/NotificationsLogs")
);
const ScheduleTasks = lazy(() => import("pages/General/ScheduleTasks"));
const staffTasks = lazy(() => import("pages/General/Tasks"));
const TaskCalender = lazy(() =>
  import("pages/General/Tasks/TasksCalender/Calender")
);
const Reminders = lazy(() => import("pages/General/Reminders/Reminders"));
const Chat = lazy(() => import("pages/General/Chat"));
const { notificationsLogs, dropdownNotifications, productionEvents, tasks } =
  generalPaths;

export const GeneralRoutes = [
  // Notifications Logs (for module)
  { path: notificationsLogs, component: NotificationsLogs },
  // Notifications Logs (for view all button in notifications dropdown)
  { path: dropdownNotifications, component: NotificationsLogs },
  { path: productionEvents, component: ScheduleTasks },
  //staff tasks
  { path: tasks, component: staffTasks },
  { path: `/Calendar`, component: TaskCalender },
  { path: `/reminders`, component: Reminders },
  {
    path: "/chat",
    component: Chat,
  },
];

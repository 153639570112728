import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";
import { TableContainer } from "components/Common";
import {
  NotificationMessage,
  apiErrorrHandler,
  extractSpecificAction,
  formatDate,
  generateColumns,
} from "utils";
import { chronicFormModuleId } from "utils/modulesIds";
import { buttonsTitles } from "common/data";
import { claimPaths } from "common/data/routePaths";
import { getAllActions } from "helpers/Permissions/permissions_helper";
import { getAllChronics } from "helpers/Claims/ChronicForm";
import {
  SingleRowAction,
  StickyHeaderTable,
} from "components/TP/Common/Tables";

const ChronicFormsHistoryModal = ({
  beneficiary,
  isModal,
  setIsModal,
  classNames,
  title,
  isServicesPermission = false,
}) => {
  const [claims, setClaims] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [columns, setColumns] = useState([]);
  const [actionFields, setActionFields] = useState([]);
  const [module, setModule] = useState(null);

  const [isServicesModal, setIsServicesModal] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [chronicRef, setChronicRef] = useState(null);

  const onCloseClick = () => setIsModal(false);

  useEffect(() => {
    // remove beneficiary name and beneficiary code columns
    const unwantedIds = [1724, 1725];
    if (actionFields?.length > 0) {
      const generatedColumns = generateColumns(
        actionFields.filter((item) => !unwantedIds.includes(item.id))
      );
      setColumns([
        ...generatedColumns,
        {
          Header: buttonsTitles.actions,
          accessor: "actions",
          disableFilters: true,
          Cell: (cellProps) => {
            // Actions Icons in each row with its props
            const row = cellProps.row.original;
            // update
            const isUpdatePermission = extractSpecificAction(module, 2);
            // view
            const isViewPermission = extractSpecificAction(module, 8);
            return (
              <div className="d-flex gap-2">
                {isViewPermission ? (
                  <SingleRowAction
                    actionName="view"
                    target="_blank"
                    to={`${claimPaths.chronicForm}/view/${row?.id}`}
                    iconClass="mdi mdi-eye"
                    record={row}
                  />
                ) : null}
                {isUpdatePermission ? (
                  <SingleRowAction
                    actionName={"Update"}
                    target="_blank"
                    to={`${claimPaths.chronicForm}/edit/${row?.id}`}
                    iconClass="mdi mdi-pencil"
                    record={row}
                  />
                ) : null}
                {isServicesPermission ? (
                  <SingleRowAction
                    actionName={"View Services"}
                    onClick={() => {
                      setIsServicesModal(true);
                      setServicesList(
                        row?.services?.map((item) => item?.pivot)
                      );
                      setChronicRef(row?.reference);
                    }}
                    iconClass="mdi mdi-table"
                    record={row}
                  />
                ) : null}
              </div>
            );
          },
        },
      ]);
    }
  }, [actionFields]);

  const getChronics = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
  }) => {
    try {
      setIsLoading(true);

      const res = await getAllChronics({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        beneficiary_id: beneficiary.id,
      });
      const resClaims = res.items;
      setClaims(resClaims);
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    }
  };

  const getClaimPermission = async () => {
    setLoading(true);
    try {
      const level2Res = await getAllActions(chronicFormModuleId);
      setActionFields(level2Res.item.fields);
      setModule(level2Res.item);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModal) {
      getClaimPermission();
    }
  }, [isModal]);

  return (
    <Fragment>
      <button
        className={classNames ? classNames : "btn btn-primary"}
        type="button"
        disabled={!beneficiary}
        onClick={() => setIsModal(true)}
      >
        {title ? title : buttonsTitles.chronicHistory}
      </button>

      {isModal && (
        <Modal
          size="xl"
          isOpen={isModal}
          toggle={onCloseClick}
          backdrop="static"
          className="modal-fullscreen"
        >
          <div className="modal-header">
            {/* Modal Header Title */}
            <h5 className="modal-title mt-0" id="myModalLabel">
              <Fragment>{`Chronics : ${beneficiary?.name}`}</Fragment>
            </h5>

            {/* close button on the top right section */}
            <button
              aria-label="Close Modal"
              type="button"
              onClick={onCloseClick}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
          </div>

          {/* Modal Body */}
          <div className="modal-body">
            <TableContainer
              removeActionsColumns={true}
              removeActionsButtons={true}
              columns={columns}
              data={claims}
              handleAdd={null}
              pagination={pagination}
              queryAddOn={{
                beneficiary_id: beneficiary.id,
              }}
              getData={getChronics}
              loading={isLoading}
            />
          </div>

          <div className="modal-footer">
            <button
              aria-label="Close Modal"
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              {buttonsTitles.close}
            </button>
          </div>
        </Modal>
      )}

      {isServicesModal && (
        <Modal
          size="lg"
          isOpen={isServicesModal}
          toggle={() => {
            setIsServicesModal(false);
            setServicesList([]);
          }}
          backdrop="static"
        >
          <div className="modal-header">
            {/* Modal Header Title */}
            <h5 className="modal-title mt-0" id="myModalLabel">
              <Fragment>{chronicRef} Services</Fragment>
            </h5>

            {/* close button on the top right section */}
            <button
              aria-label="Close Modal"
              type="button"
              onClick={() => {
                setIsServicesModal(false);
                setServicesList([]);
              }}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
          </div>

          {/* Modal Body */}
          <div className="modal-body">
            <StickyHeaderTable
              columns={["Code", "Name", "Start Date", "Status"]}
              noData={servicesList.length === 0 ? true : false}
              isLoading={false}
            >
              {servicesList.map((item, index) => (
                <tr key={`${item.code}-${index}`}>
                  <td>{item.code}</td>
                  <td>{item.service?.short_name}</td>
                  <td>{formatDate(item?.start_date)}</td>
                  <td>{item.action?.name}</td>
                </tr>
              ))}
            </StickyHeaderTable>
          </div>

          <div className="modal-footer">
            <button
              aria-label="Close Modal"
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setIsServicesModal(false);
                setServicesList([]);
              }}
            >
              {buttonsTitles.close}
            </button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default withTranslation()(ChronicFormsHistoryModal);

import { benefitIds, buttonsTitles } from "common/data";
import React, { Fragment, useEffect, useState } from "react";
import { AsyncSearchSelect, FormModal } from "../Common/Forms";
import { NotificationMessage, apiErrorrHandler, cleanObject } from "utils";
import { getBeneficaryFOBSpent } from "helpers/Production/Policies/beneficaries_helper";
import { StickyHeaderTable } from "../Common/Tables";
import MultiSkeleton from "../Common/MultiSkeleton";
import { MEDICALCODES } from "helpers/url_helper";

const ViewFOBSpentModal = ({ beneficiary, policyId, benefitId, disabled }) => {
  const [fobSpents, setFobSpents] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const [selectedGeneralAssessment, setSelectedGeneralAssesment] = useState("");

  const onCloseClick = () => setIsModal(false);

  const getBeneficiaryLimits = async ({ general_assessment_id }) => {
    setSubmitting(true);
    try {
      const res = await getBeneficaryFOBSpent(
        cleanObject({
          beneficiary_id: beneficiary.id,
          general_assessment_id,
        })
      );
      setFobSpents(res.item);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
      console.log("error", error);
      setFobSpents([]);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (isModal) {
      getBeneficiaryLimits({
        general_assessment_id: selectedGeneralAssessment,
      });
    }
  }, [isModal, selectedGeneralAssessment]);

  const getFOBValue = (benefitsArray, fobId) => {
    return benefitsArray?.find((item) => item?.id === fobId)?.calculated_amount;
  };
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-secondary w-md"
        onClick={() => setIsModal(true)}
        disabled={disabled}
      >
        {buttonsTitles.fobSpent}
      </button>

      {isModal && (
        <FormModal
          modalSize="xl"
          show={isModal}
          onCloseClick={onCloseClick}
          modalTitle={buttonsTitles.fobSpent}
          hideSaveButton={true}
        >
          <Fragment>
            <AsyncSearchSelect
              id="712"
              name="general_assessment_id"
              inputField={{
                label_name: "General Assessment",
              }}
              value={selectedGeneralAssessment}
              customOnChange={(selectedValue) => {
                setSelectedGeneralAssesment(
                  selectedValue ? selectedValue.id : ""
                );
              }}
              urlAddOns={{
                medical_code_type_id: 2,
                parent_id: "",
              }}
              url={MEDICALCODES}
              disableFetch={true}
              clearable={true}
              searchKey="name"
              mappingLabel="name"
              mappingValue="id"
              wrapperClassNames="mb-3"
            />
            <StickyHeaderTable
              columns={[
                "Policy Name",
                "Underwriting Year",
                "AMB $",
                "IN $",
                "PM $",
                "DV $",
                "DT $",
                "OP $",
              ]}
              isLoading={isSubmitting}
              loadingRows={10}
              noData={fobSpents.length === 0}
            >
              {fobSpents.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.underwriting_year}</td>
                  <td>{getFOBValue(item?.benefits, benefitIds.ambulatory)}</td>
                  <td>{getFOBValue(item?.benefits, benefitIds.inPatient)}</td>
                  <td>{getFOBValue(item?.benefits, benefitIds.pharmacy)}</td>
                  <td>
                    {getFOBValue(item?.benefits, benefitIds.doctorVisits)}
                  </td>
                  <td>{getFOBValue(item?.benefits, benefitIds.dental)}</td>
                  <td>{getFOBValue(item?.benefits, benefitIds.optical)}</td>
                </tr>
              ))}
            </StickyHeaderTable>
          </Fragment>
        </FormModal>
      )}
    </Fragment>
  );
};

export default ViewFOBSpentModal;

import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  AddMessage,
  deleteMessage,
  getConversations,
  readMessage,
  showConverstation,
  updateConversation,
} from "helpers/General/conversation_helper";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import ChatSidebar from "components/TP/Chat/ChatSidebar";
import moment from "moment";
import { NotificationMessage, apiErrorrHandler } from "utils";
import MultiSkeleton from "../Common/MultiSkeleton";
import { ModalSpinner } from "../Common/Forms";
import { LoadingModal } from "../Common/Modals";
import { buttonsTitles } from "common/data";
import SendConversationsViaEmail from "./SendConversationsViaEmail";
import { SingleRowAction } from "../Common/Tables";
import { useModuleActions } from "hooks";
import Draggable from "react-draggable";

const ChatModal = ({ show, onCloseClick, resourceId }) => {
  const [messageBox, setMessageBox] = useState(null);
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [convLoading, setConvLoading] = useState(false);
  const [newName, setNewName] = useState("");
  const [selectedConversation, setSelectedConversation] = useState(null);
  const module_id = useSelector((state) => state?.Module?.id);

  const { isDeleteFilePermission } = useModuleActions();
  const [conversations, setConversations] = useState([]);
  const myAccessType = JSON.parse(localStorage.getItem("authUser"))?.access_type
    ?.id;

  //get all conversations of same resource id and module id
  const getAllConversations = async () => {
    setIsLoading(true);
    try {
      const response = await getConversations({
        per_page: 1000,
        page: 1,
        module_id,
        resource_id: resourceId,
        access_type_id: myAccessType !== 1 ? myAccessType : null, //if user access type 1 (tpa) get all convs, else get convs of this user access type
      });
      const filteredConversations = response?.items;
      setConversations(filteredConversations);
      if (filteredConversations?.length > 0) {
        setSelectedConversation(filteredConversations[0]?.id);
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  //get all conversations depending on resourceId
  useEffect(() => {
    if (resourceId) {
      getAllConversations();
    }
  }, [resourceId]);

  //add messagge to ui and db
  const sendMsgSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      await AddMessage(selectedConversation, {
        message,
        user_id: JSON.parse(localStorage.getItem("authUser"))?.id,
      });
      setMessage("");
      getConversation(selectedConversation);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setIsSending(false);
    }
  };

  // Mark Message as Read
  const handleReadMessage = async (messageId) => {
    setIsSaving(true);
    try {
      await readMessage(messageId, {
        is_read: 1,
      });
      NotificationMessage("success", "Message Marked As Read");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsSaving(false);
    }
  };
  // Delete Message
  const handleDeleteMessage = async (messageId) => {
    setIsDeleting(true);
    try {
      await deleteMessage(messageId);
      getConversation(selectedConversation);
      NotificationMessage("success", "delete");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  //update conversation name in db & ui
  const onUpdateSubmit = async (e) => {
    e.preventDefault();
    if (newName.trim() !== "") {
      setIsUpdating(true);
      try {
        const updatedConv = await updateConversation(selectedConversation, {
          name: newName,
        });
        setNewName("");
        setIsOpen(false);
        getConversation(selectedConversation);
        setConversations((prev) => {
          return prev.map((item) =>
            item.id === updatedConv.item.id ? updatedConv.item : item
          );
        });
      } catch (error) {
        const errorMessage = apiErrorrHandler(error);
        NotificationMessage("error", errorMessage);
      } finally {
        setIsUpdating(false);
      }
    }
    setNewName("");
  };

  //show conversation for each one
  const getConversation = async (id) => {
    setConvLoading(true);
    try {
      const response = await showConverstation(id);
      setConversation(response.item);
      setNewName(response.item?.name);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setConvLoading(false);
    }
  };

  //get chat of selected conversation
  useEffect(() => {
    if (selectedConversation) {
      getConversation(selectedConversation);
    }
  }, [selectedConversation]);

  //message of my user id
  const isMyMessage = (userId) => {
    const user = JSON.parse(localStorage.getItem("authUser"))?.id;
    return user === userId ? true : false;
  };

  // check if the message is from TPA user or not
  const isTPAMessage = (user) => {
    const accessTypeId = user?.access_type?.id;
    return accessTypeId === 1 ? true : false;
  };
  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  };

  useEffect(() => {
    if (!isEmpty(conversation?.messages)) scrollToBottom();
  }, [conversation]);

  return (
    <Fragment>
      <Draggable handle=".modal-header" defaultClassNameDragging="dragging">
        <Modal
          size="xl"
          isOpen={show}
          toggle={onCloseClick}
          className="modal-fullscreen"
        >
          {/* Modal header */}
          <div
            className="modal-header bg-light bg-opacity-50"
            id="myModalLabel"
          >
            {/* Modal Header Title */}
            {convLoading || IsLoading ? (
              <div className="w-25">
                <MultiSkeleton length={1} />
              </div>
            ) : (
              <h5 className="modal-title mt-0 ">
                {conversation ? (
                  conversation.name
                ) : (
                  <p className="text-danger fw-bold">
                    Please Press "Add Conversation" Button To Start Chatting
                  </p>
                )}
              </h5>
            )}
            {/* show update icon if there is a conversation */}
            {conversation ? (
              <ul className="list-inline user-chat-nav text-end mb-0">
                {/* Mark as Read for last Message in conversation */}
                {conversation.messages?.length > 0 && (
                  <li className="list-inline-item d-none d-sm-inline-block">
                    <button
                      type="button"
                      disabled={isSaving}
                      // send the last message of this conversation
                      onClick={() =>
                        handleReadMessage(
                          conversation.messages[
                            conversation.messages.length - 1
                          ]?.id
                        )
                      }
                      className=" btn btn-primary d-flex align-items-center justify-content-center gap-2"
                    >
                      <i className="bx bx bx-check-double font-size-17" />
                      <span>Mark As Read</span>
                    </button>
                  </li>
                )}

                {/* Send The whole conversations to email */}
                <li className="list-inline-item d-none d-sm-inline-block">
                  <SendConversationsViaEmail
                    disabled={
                      conversation?.messages?.length === 0 ? true : false
                    }
                    conversationId={conversation.id}
                  />
                </li>

                <li className="list-inline-item d-none d-sm-inline-block">
                  <Button
                    onClick={() => setIsOpen(true)}
                    className="bg-transparent  border-0 text-black shadow-none d-flex align-items-center justify-content-center"
                    style={{ paddingRight: "50px" }}
                  >
                    <i className="bx bx-cog font-size-17" />
                  </Button>
                  <Modal
                    isOpen={isOpen}
                    toggle={() => setIsOpen(false)}
                    centered
                  >
                    <ModalHeader>Update Conversation name</ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={onUpdateSubmit}
                        className="d-flex flex-column"
                      >
                        <Input
                          type="text"
                          placeholder="Update name"
                          value={newName}
                          onChange={(e) => {
                            setNewName(e.target.value);
                          }}
                          className="ps-2 pr-4 w-100 border-1"
                        />
                        <Button
                          type="submit"
                          className="mt-3 btn-blue align-self-end"
                          disabled={!newName || isUpdating ? true : false}
                        >
                          {buttonsTitles.update}
                        </Button>
                      </Form>
                    </ModalBody>
                  </Modal>
                </li>
              </ul>
            ) : null}

            {/* close button on the top right section */}
            <button
              aria-label="Close Modal"
              type="button"
              onClick={onCloseClick}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
          </div>

          {/* Conversation container */}
          <React.Fragment>
            <div className="page-content p-2">
              <Container fluid>
                <Row>
                  <Col lg="12">
                    <div className="d-lg-flex">
                      {/* left sidebar */}
                      <div className="me-lg-0">
                        <ChatSidebar
                          setSelectedConversation={setSelectedConversation}
                          selectedConversation={selectedConversation}
                          refetchConversations={() => getAllConversations()}
                          conversations={conversations}
                          isLoading={IsLoading}
                          resourceId={resourceId}
                        />
                      </div>
                      {/* chat  */}
                      <div className="w-100 user-chat ">
                        <Card>
                          <div>
                            <div className="chat-conversation p-3 ">
                              <ul className="list-unstyled">
                                <PerfectScrollbar
                                  style={{ height: "479px" }}
                                  containerRef={(ref) => setMessageBox(ref)}
                                >
                                  {convLoading ? (
                                    <ModalSpinner color="blue" />
                                  ) : (
                                    conversation &&
                                    conversation?.messages.length > 0 &&
                                    conversation?.messages
                                      ?.sort((a, b) => {
                                        return a.id - b.id;
                                      })
                                      ?.map((msg, index) => (
                                        <Fragment key={msg?.id}>
                                          <div
                                            className={`d-flex ${
                                              isTPAMessage(msg?.user)
                                                ? "justify-content-start flex-row-reverse me-3"
                                                : " justify-content-start"
                                            }  gap-1`}
                                          >
                                            <div
                                              className="card bg-blue bg-opacity-10"
                                              style={
                                                isTPAMessage(msg?.user)
                                                  ? {
                                                      borderBottomRightRadius:
                                                        "0rem",
                                                    }
                                                  : {
                                                      borderBottomLeftRadius:
                                                        "0rem",
                                                    }
                                              }
                                            >
                                              <div className="card-body p-2">
                                                <h4 className="text-blue">
                                                  {isMyMessage(msg?.user?.id)
                                                    ? "You"
                                                    : msg?.user?.name}
                                                </h4>
                                                <p className="mb-1">
                                                  {msg.message}
                                                </p>
                                                <p className="mb-0">
                                                  <i className=" bx bx-time font-size-12 me-1" />
                                                  <span>
                                                    {moment(
                                                      msg?.created_at
                                                    ).format("DD-MM-YYYY ")}
                                                    <span className="time text-danger">
                                                      {moment(
                                                        msg?.created_at
                                                      ).format("hh:mm a")}
                                                    </span>
                                                  </span>
                                                </p>
                                              </div>
                                            </div>

                                            {/* Delete for last message of the conversation with the is_read  0 and access type id is 1 */}
                                            {isTPAMessage(msg?.user) &&
                                              conversation.messages[
                                                conversation.messages.length - 1
                                              ]?.id === msg.id &&
                                              msg?.is_read === 0 &&
                                              isDeleteFilePermission && (
                                                <SingleRowAction
                                                  iconClass="bx bxs-trash-alt"
                                                  color="text-danger"
                                                  actionName="Delete Message"
                                                  record={msg}
                                                  onClick={() =>
                                                    handleDeleteMessage(msg.id)
                                                  }
                                                />
                                              )}
                                          </div>
                                        </Fragment>
                                      ))
                                  )}
                                </PerfectScrollbar>
                              </ul>
                            </div>
                            {/* show input & send button only if there is a conversation */}
                            {conversation ? (
                              <div className="p-3 chat-input-section">
                                <Form onSubmit={sendMsgSubmit}>
                                  <div className="d-flex align-items-center gap-4">
                                    <Input
                                      className="rounded-pill ps-3 bg-blue bg-opacity-10 border-0"
                                      type="text"
                                      placeholder="Enter message..."
                                      value={message}
                                      onChange={(e) => {
                                        const text = e.target.value;
                                        // max length of message is 255 characters
                                        if (text.length > 255) return;
                                        setMessage(text);
                                      }}
                                    />

                                    {/* Message Characters Count */}
                                    {message.length > 0 ? (
                                      <div>{message.length}/255</div>
                                    ) : null}
                                    <Button
                                      className="btn btn-blue w-md d-flex align-items-center justify-content-center rounded-pill"
                                      type="submit"
                                      disabled={
                                        !message || isSending ? true : false
                                      }
                                    >
                                      <span className="d-none d-sm-inline-block me-2">
                                        {buttonsTitles.send}
                                      </span>
                                      <i className="bx bx-send font-size-13"></i>
                                    </Button>
                                  </div>
                                </Form>
                              </div>
                            ) : null}
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        </Modal>
      </Draggable>

      <LoadingModal isOpen={isSaving} />
    </Fragment>
  );
};

export default ChatModal;
